import React from 'react';
import GeneralFiltersTooltip from '../GeneralFiltersTooltip/GeneralFiltersTooltip';
import { CLASS_NAME_PREFIX, CUSTOM_ERASE_ICON, FILTERS_SECTIONS } from '../../constants';
import { useDispatch } from 'react-redux';
import {
  clearAllFilters,
  setSelectedDrillToDetailFilters,
  setSelectedFilters,
} from '../../redux/actions';
import { generateRandom } from '../../../../../util/generateRandom';
import { changeCCType } from '../../../../features/organization/redux/actions';

/**
 * @TODO: Add advanced filters in options state if required in the future
 * @returns {Element}
 * @constructor
 */
export default function GeneralFiltersMore() {
  const dispatch = useDispatch();
  const { COUNTRY, GBU, BL, CC } = FILTERS_SECTIONS;
  const types = [COUNTRY, GBU, BL, CC];

  const clearAll = () => {
    dispatch(clearAllFilters(generateRandom()));
    dispatch(changeCCType([]));
    dispatch(setSelectedFilters({ bl: [], cc: [], gbu: [], country: [] }));
    dispatch(setSelectedDrillToDetailFilters({ bl: [], cc: [], gbu: [], country: [] }));

    types.forEach(type => {
      const selectAllNode = document.getElementById(`${type}-Select All`);
      if (selectAllNode) {
        if (selectAllNode.checked) {
          selectAllNode.click();
          dispatch(setSelectedFilters({ bl: [], cc: [], gbu: [], country: [] }));
          dispatch(setSelectedDrillToDetailFilters({ bl: [], cc: [], gbu: [], country: [] }));
        }
      }
    });
  };

  return (
    <div className="flex justify-between items-center">
      <GeneralFiltersTooltip title="More options" placement="left">
        <div className={`${CLASS_NAME_PREFIX}moreOptions`}>...</div>
      </GeneralFiltersTooltip>
      <GeneralFiltersTooltip title="Clear all filters" placement="left">
        <div className={`${CLASS_NAME_PREFIX}clearAll`}>
          <img src={CUSTOM_ERASE_ICON.small} alt="" onClick={clearAll} />
        </div>
      </GeneralFiltersTooltip>
    </div>
  );
}
