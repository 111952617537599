import React from 'react';
import { EmwButton, EmwDropDownList } from '../../../../../lib/common';
import { StyledAdministration } from '../styled';
import EmwTypography from '../../../../components/EmwTypography/EmwTypography';
import NotificationSection from './components/AdminToolsSection/components/NotificationSection/NotificationSection';
import ImpersonatingSection from './components/AdminToolsSection/components/ImpersonatingSection/ImpersonatingSection';
import BackInTimeSection from './components/AdminToolsSection/components/BackInTimeSection/BackInTimeSection';
import ExportSections from './components/AdminToolsSection/components/ExportSections/ExportSections';
import HZAUnderMaintenance from './components/AdminToolsSection/components/HZAUnderMaintenance/HZAUnderMaintenance';
import TDPUnderMaintenance from './components/AdminToolsSection/components/TDPUnderMaintenance/TDPUnderMaintenance';
import AdminToolsEditBtn from './components/AdminToolsSection/components/AdminToolsEditBtn/AdminToolsEditBtn';
import {
  getAllRoleGroupRightsApi,
  saveRoleGroupRightsApi,
  getAllBccsApi,
  saveBccDetailsApi,
  getFixedPriceOutsourcingTargets,
  getOffshoringTargets,
  saveFixedPriceOutsourcingTargets,
  saveOffshoringTargets,
  saveUserEmailsMapping,
  getUserEmailsMapping,
  getKpiConfiguration,
  saveKpiConfiguration,
  getExternalEmployees,
  saveExternalEmployees,
  getAdministrators,
  saveAdministrator,
} from '../../../organization/redux/api';
import { TdsDivider } from '@tds/react';
import OwlIcon from '../../../../../lib/icons/OwlIcon';
import { Box } from '@mui/material';
import OpsCockpit from './components/AdminToolsExport/components/OpsCockpit/OpsCockpit';
import AccessDenied from '../AccessDenied/AccessDenied';

export default function AdminTools({ user }) {
  return (
    <StyledAdministration className="bg-bluegrey-25">
      {user.showAdminPage ? (
        <div className="flex flex-1 flex-col items-center admin-tools">
          <div className="flex space-x-xs mt-xxl">
            <EmwButton variant="filled" size="small">
              Admin Services
            </EmwButton>
            <EmwButton variant="outline" size="small" disabled>
              Database Admin
            </EmwButton>
          </div>

          <div className="box-element mt-xl pl-m pr-m pb-xs">
            <EmwTypography fontSize={6} classes="text-primary-500 font-bold py-xxs">
              ADMIN DEDICATED SERVICES
            </EmwTypography>
            <Box borderBottom="2px solid #041295" />
            <div className="admin-actions flex mt-m">
              <div className="left-column pr-m">
                <>
                  <NotificationSection user={user} />
                  <ImpersonatingSection user={user} />
                  <HZAUnderMaintenance user={user} />
                  <TDPUnderMaintenance user={user} />
                  <BackInTimeSection user={user} />
                </>

                <ExportSections user={user} />
              </div>

              <div className="right-column pl-m">
                <div className="mb-s">
                  <p className="text-scale-6 text-blugrey-500 mb-s ">
                    Administrator and Super User Configurations
                  </p>
                  <div className="flex justify-between">
                    <AdminToolsEditBtn
                      id="roles-edit"
                      title="Role Access Rights"
                      pageTitle="Role Access Rights"
                      fixedColumns={2}
                      getApi={getAllRoleGroupRightsApi}
                      saveApi={saveRoleGroupRightsApi}
                      colWidth="180"
                    />
                    <AdminToolsEditBtn
                      id="kpi-roles-edit"
                      title="KPI Access Rights"
                      pageTitle="KPI Access Rights"
                      fixedColumns={2}
                      getApi={getKpiConfiguration}
                      saveApi={saveKpiConfiguration}
                      colWidth="100"
                    />
                    <AdminToolsEditBtn
                      id="emails-edit"
                      title="Mapping User Emails"
                      pageTitle="Mapping User Emails"
                      fixedColumns={1}
                      getApi={getUserEmailsMapping}
                      saveApi={saveUserEmailsMapping}
                      colWidth={[20, 20, 20, 100, 200, 200, 200, 20]}
                    />
                  </div>
                </div>
                <TdsDivider size="small" />
                <div className="mt-s mb-s">
                  <div className="flex">
                    <p className="text-scale-6 text-blugrey-500 mr-xs">Super User Configurations</p>
                    <OwlIcon />
                  </div>

                  <div className="flex justify-between">
                    <AdminToolsEditBtn
                      id="bcc-edit"
                      title="BCC of interest"
                      pageTitle="BCC of interest"
                      fixedColumns={4}
                      getApi={getAllBccsApi}
                      saveApi={saveBccDetailsApi}
                    />
                    <AdminToolsEditBtn
                      hasCustomIcon={true}
                      icon="track_changes"
                      id="fixed-price-edit"
                      title="Fixed Price Outsourcing Target"
                      pageTitle="Configuration of Fixed Price Outsourcing ratio Target per GBU"
                      fixedColumns={3}
                      getApi={getFixedPriceOutsourcingTargets}
                      saveApi={saveFixedPriceOutsourcingTargets}
                    />
                    <AdminToolsEditBtn
                      hasCustomIcon={true}
                      icon="track_changes"
                      id="offshoring--edit"
                      title="OffShoring Target"
                      pageTitle="Configuration of Offshoring ratio Target per GBU"
                      fixedColumns={1}
                      getApi={getOffshoringTargets}
                      saveApi={saveOffshoringTargets}
                    />
                  </div>
                  <div className="flex ">
                    <AdminToolsEditBtn
                      hasCustomIcon={true}
                      icon="engineering"
                      id="subcontractor-edit"
                      title="Manage External Worker"
                      pageTitle="Manage External Worker"
                      fixedColumns={1}
                      canAdd={true}
                      getApi={getExternalEmployees}
                      saveApi={saveExternalEmployees}
                      saveNewRowApi={saveExternalEmployees}
                      mandatoryCols={[1, 2, 3, 4]}
                    />
                    <AdminToolsEditBtn
                      canAdd={true}
                      hasCustomIcon={true}
                      icon={'admin_panel_settings'}
                      id="administrator-roles-edit"
                      title="Manage Admin Roles"
                      pageTitle="Manage Admin Roles"
                      fixedColumns={1}
                      getApi={getAdministrators}
                      saveApi={saveAdministrator}
                      saveNewRowApi={saveAdministrator}
                      mandatoryCols={[1, 5]}
                    />
                  </div>
                </div>
                <TdsDivider size="small" />
                <OpsCockpit />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <AccessDenied />
      )}
    </StyledAdministration>
  );
}
