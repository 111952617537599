import React, { useState } from 'react';
import './styled';
import { EmwButton } from '../../../../../../../lib/common';
import EmwTypography from '../../../../../../components/EmwTypography/EmwTypography';
import {
  getEeiDataForOrganizationApi,
  getWfbDataForOrganizationApi,
  uploadEeiFromWebFormsApi,
  uploadWFBApi,
} from '../../../../redux/api';
import { orgDetailsSelector } from '../../../../redux/selectors';
import { useSelector } from 'react-redux';
import EmwHotTable from '../../../../../../components/EmwHotTable/EmwHotTable';
import WFBForm from './WFBForm';
import EEIForm from './EEIForm';

export default function KPIEditButton({
  selectedOption,
  selectedEditYear,
  selectedKPI,
  regionName,
  countryName,
  handleUpload,
  errorComments,
  errorFileName,
  getKPIErrors,
  isFromByCountry,
  handleDelete,
  payloadArgs,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const orgDetails = useSelector(orgDetailsSelector);
  const cardDetails = orgDetails.list.data.cardDetails;
  const orgId = cardDetails.id;
  const type = cardDetails.type;

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <EmwButton
        variant="filled"
        title="Edit KPI"
        size="small"
        onClick={handleClick}
        id="edit-kpi-btn"
        disabled={!selectedOption}
      >
        <span className="flex">
          <EmwTypography classes="font-bold">Edit</EmwTypography>
        </span>
      </EmwButton>
      {isOpen && selectedKPI === 'EEI' && isFromByCountry && (
        <EEIForm
          id="eeiHotTable"
          getApi={getEeiDataForOrganizationApi}
          saveApi={handleUpload}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          title="Edit file"
          orgId={orgId}
          type={type}
          selectedYear={selectedEditYear}
          selectedKPI={selectedKPI}
          regionName={regionName}
          countryName={countryName}
          handleUpload={handleUpload}
          selectedOption={selectedOption}
          handleDelete={handleDelete}
        />
      )}
      {isOpen && selectedKPI === 'EEI' && !isFromByCountry && (
        <EmwHotTable
          id="eeiHotTable"
          getApi={getEeiDataForOrganizationApi}
          saveApi={uploadEeiFromWebFormsApi}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          orgId={orgId}
          type={type}
          selectedYear={selectedEditYear}
          selectedKPI={selectedKPI}
          regionName={regionName}
          selectedOption={selectedOption}
          countryName={countryName}
          cardDetails={cardDetails}
          handleUpload={handleUpload}
          errorComments={errorComments}
          errorFileName={errorFileName}
          getKPIErrors={getKPIErrors}
          hasReadMeFile={true}
          handleDelete={handleDelete}
          payloadArgs={payloadArgs}
        />
      )}
      {isOpen && selectedKPI === 'WFB' && (
        <WFBForm
          id="wfbHotTable"
          getApi={getWfbDataForOrganizationApi}
          saveApi={uploadWFBApi}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          orgId={orgId}
          type={type}
          selectedYear={selectedEditYear}
          selectedKPI={selectedKPI}
          regionName={regionName}
          selectedOption={selectedOption}
          countryName={countryName}
          cardDetails={cardDetails}
          handleUpload={handleUpload}
          errorComments={errorComments}
          errorFileName={errorFileName}
          getKPIErrors={getKPIErrors}
          handleDelete={handleDelete}
          payloadArgs={payloadArgs}
        />
      )}
    </>
  );
}
