import React, { useState } from 'react';
import { DialogContent, DialogTitle, Grid } from '@mui/material';
import GuestPowerGuestModalForm from './GuestPowerGuestModalForm';
import EmwTypography from '../../../../../components/EmwTypography/EmwTypography';
import { TdsIcon } from '@tds/react';
import { EmwButton } from '../../../../../../lib/common';
import { StyledGuestPowerGuestDialog } from '../styled';
import EmwDialog from '../../../../../components/EmwDialog/EmwDialog';
import FeedbackButton from '../../../../feedback/FeedbackButton';
import { feedbackInsideModal } from '../../../../feedback/redux/actions';
import { useDispatch } from 'react-redux';
import { USER_ACTIVITY_SERVICES } from '../../../../userActivity/constants';

export default function OrganizationCardModal({
  isOpen,
  setIsOpen,
  guests,
  powerGuests,
  excoms,
  showExcoms,
  showPowerGuests,
  showGuests,
  setExcoms,
  setGuests,
  setPowerGuests,
  defaultUser,
  canEdit,
  handleSave,
  handleCancel,
  btnSave,
}) {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isCloseOpen, setIsCloseOpen] = useState(false);
  const [isResetOpen, setIsResetOpen] = useState(false);
  const [shouldReset, setShouldReset] = useState(false);
  const title = 'Edit Guests/Power Guests';
  const dispatch = useDispatch();
  const toggleConfirmSave = () => {
    setIsConfirmOpen(!isConfirmOpen);
  };

  const save = () => {
    handleSave();
    setIsConfirmOpen(false);
    setIsOpen(false);
    dispatch(feedbackInsideModal(false));
  };

  const handleClose = () => {
    setIsCloseOpen(!isCloseOpen);
    dispatch(feedbackInsideModal(false));
  };

  const close = () => {
    handleCancel();
    setIsCloseOpen(false);
    setIsOpen(false);
  };

  const handleReset = () => {
    setIsResetOpen(!isResetOpen);
  };

  const reset = () => {
    setShouldReset(true);
    setTimeout(() => {
      setShouldReset(false);
      handleReset();
    }, 1);
  };

  return (
    <>
      <StyledGuestPowerGuestDialog open={isOpen} maxWidth="xl" fullWidth>
        <DialogTitle className="dialog-header">
          <Grid container alignItems="center" className="dialog-header-content">
            <Grid item>
              <EmwTypography classes="text-primary-500" fontWeight="bold" fontSize={6}>
                {title}
              </EmwTypography>
            </Grid>
            <Grid item ml="auto">
              <Grid container alignItems="center" justifyContent="end">
                <Grid item ml={0.5}>
                  <EmwButton
                    variant="ghost"
                    title="Reset"
                    id="btnReset"
                    onClick={() => setIsResetOpen(!isResetOpen)}
                  >
                    <TdsIcon icon="sync" />
                  </EmwButton>
                </Grid>

                <Grid sx={{ zIndex: 0 }} item ml={0.5}>
                  <EmwButton
                    variant="outline"
                    size="small"
                    title="Close"
                    id="btnCloseModal"
                    onClick={handleClose}
                  >
                    Close
                  </EmwButton>
                </Grid>
                {btnSave.visible && (
                  <Grid item ml={0.5}>
                    <EmwButton
                      id="save-guest-power-guest"
                      variant="filled"
                      size="small"
                      title="Save"
                      onClick={toggleConfirmSave}
                    >
                      Save
                    </EmwButton>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className="dialog-content">
          <GuestPowerGuestModalForm
            showExcoms={showExcoms}
            showGuests={showGuests}
            showPowerGuests={showPowerGuests}
            excoms={excoms}
            setExcoms={setExcoms}
            guests={guests}
            powerGuests={powerGuests}
            setPowerGuests={setPowerGuests}
            setGuests={setGuests}
            defaultUser={defaultUser}
            canEdit={canEdit}
            shouldReset={shouldReset}
          />
        </DialogContent>
        <FeedbackButton title={USER_ACTIVITY_SERVICES.G_PG_EDIT} />
      </StyledGuestPowerGuestDialog>

      {isConfirmOpen && (
        <EmwDialog
          isOpen={isConfirmOpen}
          setIsOpen={setIsConfirmOpen}
          callback={save}
          actionButton="Save"
          title={`Do you want to save the changes made to the G/PG Card?`}
          text="Your changes will be lost if you don't save them."
          icon="info"
          classes="save-icon"
          loading={false}
        />
      )}

      {isCloseOpen && (
        <EmwDialog
          isOpen={isCloseOpen}
          setIsOpen={setIsCloseOpen}
          callback={close}
          actionButton="Close"
          title={`Do you want to close the G/PG Card?`}
          text="Your changes will be lost if you don't save them."
          icon="warning_amber"
          classes="close-icon"
          loading={false}
        />
      )}

      {isResetOpen && (
        <EmwDialog
          isOpen={isResetOpen}
          setIsOpen={setIsResetOpen}
          callback={reset}
          actionButton="Reset"
          title={`Do you want to reset the changes made to the G/PG Card?`}
          text="Your changes will be lost if you don't save them."
          icon="warning_amber"
          classes="reset-icon"
          loading={false}
        />
      )}
    </>
  );
}
