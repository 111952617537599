import React from 'react';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #f8f9fa;
  color: #333;
  font-family: 'Arial', sans-serif;
  text-align: center;
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #041295; /* A shade of red to indicate denial */
`;

const Message = styled.p`
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
`;

const AccessDenied = () => {
  return (
    <Wrapper>
      <Title>Access Denied</Title>
      <Message>You do not have the necessary permissions to view this page.</Message>
    </Wrapper>
  );
};

export default AccessDenied;
