import { buildStep } from '../tour';
import { RIGHT_START, BTN_GUESTS_ID, BTN_CLOSE_MODAL, BTN_CLOSE_MODAL_ID } from '../constants';

export const buildRegionSteps = (
  dinamycStep,
  tour,
  list,
  hasBtnWarning,
  btnWarningsTitle,
  hasBtnFavorite,
  btnFavoriteTitle,
  text,
  hasBtnGuests,
  btnManageGPGTitle,
  hasBtnAddNewCC,
  hasBtnEdit,
  hasHc,
  hasHcDeclared,
  hasEms,
  hasHoEOs,
  hasBtnEditEnabled,
  btnEditGuests,
  showExcoms,
  showPowerGuests,
  showGuests
) => {
  dinamycStep.push(
    buildStep(tour, null, {
      id: 'organization-diagram-nodes',
      title: 'Region Column',
      text: `This column shows all the regions available. In this example ${list.data.cardDetails.region} has been selected, revealing all the countries that are part of it, in the second column (Country).
    The nodes that have a darker gray colour represent "Intuitive Path" - they lead to nodes that are under your responsibility. 
    The nodes that have a star symbol are under your responsibility.
    Click Next to continue.`,
      element: '.tour-organization-diagram-nodes',
      on: RIGHT_START,
      scroll: true,
    })
  );

  dinamycStep.push(
    buildStep(tour, null, {
      id: 'orgCard',
      title: 'Engineering Organization Card',
      text: `This is the Engineering Organization Card, where you can visualise an organization previously selected in the graph.
    This page is left intentionally balnk since Regions are used only for navigate to countries.`,
      element: '.organization-card-container',
      on: RIGHT_START,
    })
  );

  if (hasBtnWarning && hasBtnEdit && hasBtnEditEnabled) {
    dinamycStep.push(
      buildStep(tour, null, {
        id: 'btnWarnings',
        title: btnWarningsTitle,
        text: `Alerts (highlights missing items in your organization).`,
        element: '#btnWarnings',
        on: RIGHT_START,
      })
    );
  }

  if (hasBtnFavorite) {
    dinamycStep.push(
      buildStep(tour, null, {
        id: 'btnFavorite',
        title: btnFavoriteTitle,
        text: `Add to Favorites (will add/remove selected card to your favorites list).`,
        element: '#btnFavorite',
        on: RIGHT_START,
      })
    );
  }

  if (hasBtnGuests) {
    dinamycStep.push(
      buildStep(tour, null, {
        id: 'btnGuests',
        title: btnManageGPGTitle,
        text: `Guest/Power Guest (opens a modal where you can manage Guest/Power Guest access).`,
        element: BTN_GUESTS_ID,
        on: RIGHT_START,
      })
    );
  }

  if (hasBtnEdit) {
    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'btnEdit',
          title: text,
          text: `Edit  (make changes to the Engineering Organization Card).`,
          element: '.tour-edit',
          on: RIGHT_START,
        },
        null,
        null,
        100,
        null
      )
    );
  }

  if (hasHc || hasHcDeclared) {
    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'headcount',
          title: 'Engineering Organization Card Info Area',
          text: `In this area you can visualise information related to the selected organization, such as head count
      (calculated, declared), you can view what countries the organization belongs to, CC Type, and Industries it belongs to.`,
          element: '.header-elements',
          on: RIGHT_START,
        },
        null,
        null,
        100,
        null
      )
    );
  }

  if (hasEms && hasHoEOs) {
    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'ems',
          title: 'Engineering Organization Card Management Area',
          text: `In this part you can view the people that are working in specific positions, such as:
          <li>Engineering Manager</li>
           <li>Head of Engineering Operations</li>
          <li>KPI Steward </li>
          <li>Data Referent</li>
          <li>Cybersecurity Referent</li>
          <li>Ecodesign Referent</li>
          <li>UX Design Referent</li>
          <li>Data Protection Referent</li>
          <li>Make or Buy & Footprint Referent</li>
          <li>Engineering Quality Referent</li>
      The people in this list can be changed by clicking “Edit” in the top right corner.`,
          element: '.organization-card-section-1',
          on: RIGHT_START,
          scroll: true,
        },
        null,
        null,
        100,
        null
      )
    );
  }
  if (hasBtnEdit) {
    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'btnEdit',
          title: 'Edit button',
          text: `Edit  (make changes to the Engineering Organization Card).`,
          element: '.tour-edit',
          on: RIGHT_START,
        },
        null,
        null,
        100,
        async () => {
          setTimeout(() => {
            if (hasBtnEdit && hasBtnEditEnabled && document.querySelector('#btnEdit')) {
              document.querySelector('#btnEdit').click();
            }
          }, 200);
        }
      )
    );
  }

  if (hasBtnGuests && !hasBtnEdit) {
    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'btnGuests',
          title: 'Manage Guest/Power Guest  button',
          text: `Opens View Guest/Power Guest card where you can view Guests and Power Guests.`,
          element: BTN_GUESTS_ID,
          on: RIGHT_START,
        },
        null,
        null,
        500,
        async () => {
          setTimeout(() => {
            if (document.querySelector(BTN_GUESTS_ID)) {
              document.querySelector(BTN_GUESTS_ID).click();
            }
          }, 300);
        }
      )
    );

    dinamycStep.push(
      buildStep(
        tour,
        async () => {
          setTimeout(() => {
            if (document.querySelector('#btnBack')) {
              document.querySelector('#btnBack').click();
            }
          }, 200);
        },
        {
          id: 'gpgContainer',
          title: 'View Guest/Power Guest',
          text: `Guest/Power Guest (by pressing on Edit button, a modal will be open where you can manage Guest/Power Guest access).`,
          element: '.organization-card-container',
          on: RIGHT_START,
          scroll: true,
        },

        null,
        null,
        100,
        async () => {
          if (btnEditGuests) {
            setTimeout(() => {
              if (document.querySelector('#btnEdit')) {
                document.querySelector('#btnEdit').click();
              }
            }, 500);
          }
          setTimeout(() => {
            if (document.querySelector('#btnToggleExcom')) {
              document.querySelector('#btnToggleExcom').click();
            }
          }, 500);
          if (!btnEditGuests) {
            if (document.querySelector('#btnBack')) {
              document.querySelector('#btnBack').click();
            }
          }
        },
        async () => {}
      )
    );

    if (btnEditGuests) {
      if (showExcoms) {
        dinamycStep.push(
          buildStep(
            tour,
            async () => {
              await document.querySelector(BTN_CLOSE_MODAL).click();
              setTimeout(() => {
                if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                  document.querySelector(BTN_CLOSE_MODAL_ID).click();
                }
              }, 100);
            },
            {
              id: 'tour-Excom',
              title: 'Excom Category',
              text: `This is the Excom category, where we can add 
              a person   (or more) in the role of Excom. We can add or change the person in this role by pressing the Edit (pencil symbol)
              button at the right side. Click next to continue.`,
              element: '#tour-orgCard-excom',
              on: RIGHT_START,
              identifierClass: 'Excom',
              scroll: true,
            },
            null,
            null,
            500,
            async () => {},
            async () => {
              await document.querySelector(BTN_CLOSE_MODAL).click();
              setTimeout(() => {
                if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                  document.querySelector(BTN_CLOSE_MODAL_ID).click();
                }
                if (document.querySelector('#btnBack')) {
                  document.querySelector('#btnBack').click();
                }
              }, 100);
            }
          )
        );
      }

      if (showPowerGuests) {
        dinamycStep.push(
          buildStep(
            tour,
            async () => {
              if (!showExcoms) {
                await document.querySelector(BTN_CLOSE_MODAL).click();
                setTimeout(() => {
                  if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                    document.querySelector(BTN_CLOSE_MODAL_ID).click();
                  }
                }, 200);
              }
            },

            {
              id: 'tour-PowerGuest',
              title: 'Power Guest Category',
              text: `This is the Power Guest  category, where we can add 
              a person  (or more) in the role of Power Guest. We can add or change the person in this role by pressing the Edit (pencil symbol)
              button at the right side. Click next to continue.`,
              element: '#tour-orgCard-powerguest',
              on: RIGHT_START,
              identifierClass: 'PowerGuest',
              scroll: true,
            },
            null,
            null,
            500,
            async () => {},
            async () => {
              await document.querySelector(BTN_CLOSE_MODAL).click();
              setTimeout(() => {
                if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                  document.querySelector(BTN_CLOSE_MODAL_ID).click();
                }
                if (document.querySelector('#btnBack')) {
                  document.querySelector('#btnBack').click();
                }
              }, 100);
            }
          )
        );
      }

      if (showGuests) {
        dinamycStep.push(
          buildStep(
            tour,
            null,
            {
              id: 'tour-Guest',
              title: 'Guest Category',
              text: `This is the Guest  category, where we can add 
              a person  (or more) in the role of Guest. We can add or change the person in this role by pressing the Edit (pencil symbol)
              button at the right side. Click next to continue.`,
              element: '#tour-orgCard-guest',
              on: RIGHT_START,
              identifierClass: 'Guest',
              scroll: true,
            },
            null,
            null,
            500,
            async () => {
              await document.querySelector(BTN_CLOSE_MODAL).click();
              setTimeout(() => {
                if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                  document.querySelector(BTN_CLOSE_MODAL_ID).click();
                }
                if (document.querySelector('#btnBack')) {
                  document.querySelector('#btnBack').click();
                }
              }, 200);
            },
            async () => {
              await document.querySelector(BTN_CLOSE_MODAL).click();
              setTimeout(() => {
                if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                  document.querySelector(BTN_CLOSE_MODAL_ID).click();
                }
                if (document.querySelector('#btnBack')) {
                  document.querySelector('#btnBack').click();
                }
              }, 200);
            }
          )
        );
      }
    }
  }
};
