import React, { useEffect, useState } from 'react';
import EmwTypography from '../../../EmwTypography/EmwTypography';
import { useDispatch, useSelector } from 'react-redux';
import {
  availableDrillToDetailFiltersSelector,
  availableFiltersSelector,
  generalFiltersSelectors,
  selectedDrillToDetailFilters,
  selectedGeneralFilters,
} from '../../redux/selectors';
import { setSelectedDrillToDetailFilters, setSelectedFilters } from '../../redux/actions';
import { QtmCheckbox } from '@qtm/react';
import { SELECT_ALL_ID } from '../../hooks/useGeneralFilters';
import { selectAllOption2 } from '../GeneralFiltersCard/GeneralFiltersCard';

export default function GeneralFilterListItem({ type, item, style }) {
  const dispatch = useDispatch();
  const selectedFilters = useSelector(selectedGeneralFilters);
  const availableFilters = useSelector(availableFiltersSelector);

  const selectedDrillFilters = useSelector(selectedDrillToDetailFilters);
  const availableDrillFilters = useSelector(availableDrillToDetailFiltersSelector);

  const [name, setName] = useState();

  useEffect(() => {
    if (item) {
      setName(item.region ? item.name : item.code);
      let _selectedFilters = {};
      let _availableFilters = {};
      if (!window.location.hash.includes('#drilltodetails')) {
        _availableFilters = { ...availableFilters };
        _selectedFilters = { ...selectedFilters };
      } else {
        _availableFilters = { ...availableDrillFilters };
        _selectedFilters = { ...selectedDrillFilters };
      }

      _availableFilters[type].map(filter => {
        const filterChecked = _selectedFilters[type].find(
          filterCheck => filterCheck.id === filter.id
        );

        if (filterChecked) {
          filter.checked = filterChecked.checked;
        }

        return filter;
      });
    }
  }, [item]);

  useEffect(() => {
    if (!selectedFilters[type].length) {
      availableFilters[type].map(filter => (filter.checked = false));
    }
  }, [selectedFilters]);

  useEffect(() => {
    if (!selectedDrillFilters[type].length) {
      availableDrillFilters[type].map(filter => (filter.checked = false));
    }
  }, [selectedDrillFilters]);

  const handleSelectedFilters = (_selectedFilters, _availableFilters, isDrillToDetails) => {
    if (!isDrillToDetails) {
      const isCompatibleWithDrillFilters =
        selectedFilters[type] &&
        selectedFilters[type].some(selectedItem =>
          _availableFilters[type].some(availableItem => availableItem.id === selectedItem.id)
        );

      if (isCompatibleWithDrillFilters) {
        // Dispatch both to sync filters between the pages
        dispatch(setSelectedFilters(_selectedFilters));
        dispatch(setSelectedDrillToDetailFilters(_selectedFilters));
      } else {
        // Only update the current filters
        dispatch(setSelectedFilters(_selectedFilters));
      }
    } else {
      const isCompatibleWithRegularFilters =
        selectedDrillFilters[type] &&
        selectedDrillFilters[type].some(selectedItem =>
          _availableFilters[type].some(availableItem => availableItem.id === selectedItem.id)
        );
      if (isCompatibleWithRegularFilters) {
        // Dispatch both to sync filters between the pages
        dispatch(setSelectedFilters(_selectedFilters));
        dispatch(setSelectedDrillToDetailFilters(_selectedFilters));
      } else {
        // Only update the drill-to-details filters
        dispatch(setSelectedDrillToDetailFilters(_selectedFilters));
      }
    }
  };

  const handleClick = event => {
    let _selectedFilters = {};
    let _availableFilters = {};
    const isDrillToDetails = window.location.hash.includes('#drilltodetails');

    // Determine which filters to access based on the hash
    if (!isDrillToDetails) {
      _availableFilters = { ...availableFilters };
      _selectedFilters = { ...selectedFilters };
    } else {
      _availableFilters = { ...availableDrillFilters };
      _selectedFilters = { ...selectedDrillFilters };
    }

    const filterExists = _selectedFilters[type].find(filter => filter.id === item.id);

    let isSelectAll;

    if (item.id === SELECT_ALL_ID) {
      isSelectAll = event.target.checked;
      selectAllOption2[type].checked = isSelectAll;
    }

    if (filterExists) {
      _selectedFilters[type] = _selectedFilters[type].filter(filter => filter.id !== item.id);
    } else {
      if (isSelectAll) {
        _selectedFilters[type] = _availableFilters[type].map(filter => {
          filter.checked = isSelectAll;
          return filter;
        });
      } else {
        _selectedFilters[type].push(item);
      }
    }

    _availableFilters[type].map(filter => {
      if (item.id === SELECT_ALL_ID) {
        filter.checked = isSelectAll;
      } else if (filter.id === item.id) {
        filter.checked = !item.checked;
      }

      return filter;
    });

    _selectedFilters[type] = _selectedFilters[type].filter(
      selectedFilter => selectedFilter.checked
    );

    handleSelectedFilters(_selectedFilters, _availableFilters, isDrillToDetails);
  };

  return (
    <div style={style} title={name}>
      <label className="listItem flex ml-xxs overflow-hidden items-center">
        <QtmCheckbox
          inputId={`${type}-${item.code}`}
          id={`${type}-${item.code}`}
          checked={item && item.checked}
          size="small"
          onValueChanged={handleClick}
        />
        <EmwTypography fontSize={8}>{name}</EmwTypography>
      </label>
    </div>
  );
}
