import React from 'react';
import { StyledTourElement } from './styled';

export default function TourElement({ children, id }) {
  return (
    <StyledTourElement id={id} className={id}>
      {children}
    </StyledTourElement>
  );
}
