import { buildStep } from '../tour';
import { ORGANIZATION_PERSPECTIVE } from '../../../features/organization/constants';
import {
  RIGHT_START,
  BOTTOM_START,
  BTN_EXPORT_ORG_ID,
  BTN_GUESTS_ID,
  BTN_CLOSE_MODAL,
  BTN_CLOSE_MODAL_ID,
  BTN_EXPORT_CLOSE,
  TITLE_EXPORT_OPTIONS,
} from '../constants';

export default function buildCCSteps(
  dinamycStep,
  tour,
  hasBtnWarning,
  hasBtnFavorite,
  hasBtnAllocateCC,
  hasBtnGuests,
  hasBtnDelete,
  hasBtnPublish,
  hasBtnAddNewCC,
  hasBtnEdit,
  hasHc,
  hasHcDeclared,
  hasCountries,
  hasEms,
  hasHoEOs,
  hasCers,
  hasDeliveries,
  name,
  btnWarningsTitle,
  btnFavoriteTitle,
  text,
  btnManageGPGTitle,
  btnDeleteTitle,
  btnPublishTitle,
  btnEditTitle,
  hasBtnEditEnabled,
  hasDisciplines,
  hasBtnExport,
  btnEEI,
  perspective,
  btnEditGuests,
  list,
  canAddDiscipline,
  canEditDelivery,
  showExcoms,
  showPowerGuests,
  showGuests
) {
  dinamycStep.push(
    buildStep(tour, null, {
      id: 'organization-diagram-nodes',
      title: 'CC Column',
      text: `This column shows all the CC's available. In this example ${name} has been selected.
        The nodes that have a darker gray colour represent "Intuitive Path" - they lead to nodes that are under your responsibility. 
        The nodes that have a star symbol are under your responsibility.
        Click Next to continue.`,
      element: `${
        perspective === ORGANIZATION_PERSPECTIVE.BY_COUNTRY
          ? '.organization-diagram-nodes'
          : '.tour-organization-diagram-nodes'
      }`,
      on: RIGHT_START,
      scroll: true,
    })
  );

  dinamycStep.push(
    buildStep(tour, null, {
      id: 'orgCard',
      title: 'Engineering Organization Card',
      text: `This is the Engineering Organization Card, where you can visualise an organization previously selected in the graph. Here you can notice the name of the organization on top, and the start date.  In the next steps we will go into more detail about several functionalities of this area.`,
      element: '.organization-card-container',
      on: RIGHT_START,
    })
  );

  if (hasBtnWarning && hasBtnEdit && hasBtnEditEnabled) {
    dinamycStep.push(
      buildStep(tour, null, {
        id: 'btnWarnings',
        title: btnWarningsTitle,
        text: `Alerts (highlights missing items in your organization).`,
        element: '#btnWarnings',
        on: RIGHT_START,
      })
    );
  }
  if (hasBtnExport) {
    dinamycStep.push(
      buildStep(tour, null, {
        id: 'btnExport',
        title: 'Export button',
        text: `Allows you to export custom data.`,
        element: BTN_EXPORT_ORG_ID,
        on: RIGHT_START,
      })
    );
  }
  if (hasBtnFavorite) {
    dinamycStep.push(
      buildStep(tour, null, {
        id: 'btnFavorite',
        title: btnFavoriteTitle,
        text: `Add to Favorites (will add/remove selected card to your favorites list).`,
        element: '#btnFavorite',
        on: RIGHT_START,
      })
    );
  }

  if (hasBtnAllocateCC) {
    dinamycStep.push(
      buildStep(tour, null, {
        id: 'btnAllocateCC',
        title: 'Allocate CC button',
        text: `Allocate CC (allocates CC to other organization).`,
        element: '#btnAllocateCC',
        on: RIGHT_START,
      })
    );
  }
  if (hasBtnGuests) {
    dinamycStep.push(
      buildStep(tour, null, {
        id: 'btnGuests',
        title: btnManageGPGTitle,
        text: `Opens View Guest/Power Guest card where you can view Guests and Power Guests.`,
        element: BTN_GUESTS_ID,
        on: RIGHT_START,
      })
    );
  }
  if (hasBtnDelete) {
    dinamycStep.push(
      buildStep(tour, null, {
        id: 'btnDelete',
        title: btnDeleteTitle,
        text: `This will delete the cc`,
        element: '#btnDelete',
        on: RIGHT_START,
      })
    );
  }

  if (hasBtnPublish) {
    dinamycStep.push(
      buildStep(tour, null, {
        id: 'btnPublish',
        title: btnPublishTitle,
        text: `This will publish the selected cc, and make public`,
        element: '#btnPublish',
        on: RIGHT_START,
      })
    );
  }

  if (hasBtnAddNewCC) {
    dinamycStep.push(
      buildStep(tour, null, {
        id: 'btnAddNewOrganization',
        title: 'Add new organization button',
        text: `New organization (creates new organization).`,
        element: '#btnAddNewOrganization',
        on: RIGHT_START,
      })
    );
  }

  if (btnEEI && perspective === ORGANIZATION_PERSPECTIVE.BY_GBU) {
    dinamycStep.push(
      buildStep(tour, null, {
        id: 'btnEEI',
        title: "Upload/Download Kpi's data button",
        text: `Allows you to export custom data.`,
        element: '#btn-KPI',
        on: RIGHT_START,
      })
    );
  }

  if (hasBtnEdit) {
    dinamycStep.push(
      buildStep(tour, null, {
        id: 'btnEdit',
        title: btnEditTitle,
        text: `Edit (make changes to the Engineering Organization Card).`,
        element: '.tour-edit',
        on: RIGHT_START,
      })
    );
  }

  if (hasHc || hasHcDeclared) {
    dinamycStep.push(
      buildStep(tour, null, {
        id: 'headcount',
        title: 'Engineering Organization Card Info Area',
        text: `In this area you can visualise information related to the selected organization, such as head count (calculated, declared), you can view what countries the organization belongs to, CC Type, and Industries it belongs to.`,
        element: '.header-elements',
        on: RIGHT_START,
      })
    );
  }
  if (hasCountries && !hasHc && !hasHcDeclared) {
    dinamycStep.push(
      buildStep(tour, null, {
        id: 'countries',
        title: 'Engineering Organization Card Info Area',
        text: `In this area you can visualise information related to the selected organization, you can view what countries the organization belongs to, CC Type, and Industries it belongs to.`,
        element: '.header-elements',
        on: RIGHT_START,
      })
    );
  }
  if (hasEms && hasHoEOs) {
    dinamycStep.push(
      buildStep(tour, null, {
        id: 'ems',
        title: 'Engineering Organization Card Management Area',
        text: `In this part you can view the people that are working in specific positions, such as:
        <li>Engineering Manager</li>
         <li>Head of Engineering Operations</li>
        <li>KPI Steward </li>
        <li>Data Referent</li>
        <li>Cybersecurity Referent</li>
        <li>Ecodesign Referent</li>
        <li>UX Design Referent</li>
        <li>Data Protection Referent</li>
        <li>Make or Buy & Footprint Referent</li>
        <li>Engineering Quality Referent</li>
        The people in this list can be changed by clicking “Edit” in the top right corner.`,
        element: '.organization-card-section-1',
        on: RIGHT_START,
        scroll: true,
      })
    );
  }

  if (hasDeliveries) {
    dinamycStep.push(
      buildStep(tour, null, {
        id: 'hasDeliveries',
        title: 'Engineering Organization Card Delivery Area',
        text: `In this part you can view the deliveries, with sub categories such as solutions/projects, and you can see people in charge of each category. 
        You can collapse lists and view accordingly.`,
        element: '.tour-deliveries-lists',
        on: RIGHT_START,
        scroll: true,
      })
    );
  }

  if (hasDisciplines) {
    dinamycStep.push(
      buildStep(tour, null, {
        id: 'hasDisciplines',
        title: 'Engineering Organization Card Disciplines Area',
        text: `In this part you can view the disciplines and you can see people in charge of each discipline. 
        You can expand lists and view accordingly.`,
        element: '.tour-disciplines-lists',
        on: RIGHT_START,
        scroll: true,
      })
    );
  }
  if (hasBtnEdit) {
    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'btnEdit',
          title: btnEditTitle,
          text: `Edit (make changes to the Engineering Organization Card).`,
          element: '.tour-edit',
          on: RIGHT_START,
        },
        null,
        null,
        100,
        () => {
          if (hasBtnEdit && hasBtnEditEnabled && document.querySelector('#btnEdit')) {
            document.querySelector('#btnEdit').click();
          }
        }
      )
    );
  }

  if (hasBtnGuests && !hasBtnEdit) {
    dinamycStep.push(
      buildStep(
        tour,
        async () => {
          if (list.data.section1.btnAlocateCC.visible && list.data.section1.btnAlocateCC.enabled) {
            setTimeout(() => document.querySelector('#btnAllocateCC').click(), 300);
          }
          if (!hasBtnAllocateCC) {
            setTimeout(() => {
              if (document.querySelector('#btnEdit')) {
                document.querySelector('#btnEdit').click();
              }
            }, 100);
          }
          if (!hasBtnAllocateCC && hasDisciplines && canAddDiscipline) {
            if (document.querySelector('#btnEdit')) {
              await document.querySelector('#btnEdit').click();
            }
            setTimeout(() => {
              if (
                document.querySelector(
                  '#tour-orgCard-Section3DisciplinesPanel #btnExpandDisciplines'
                )
              ) {
                document
                  .querySelector('#tour-orgCard-Section3DisciplinesPanel #btnExpandDisciplines')
                  .click();
              }
            }, 500);
          }
          if (!hasBtnAllocateCC && canEditDelivery && !hasDisciplines) {
            setTimeout(() => {
              if (
                document.querySelector(
                  '#tour-orgCard-Section2DeliveriesPanel #btnEditViewExpandDeliveries'
                )
              ) {
                document
                  .querySelector(
                    '#tour-orgCard-Section2DeliveriesPanel #btnEditViewExpandDeliveries'
                  )
                  .click();
              }
            }, 200);
            setTimeout(() => {
              if (
                document.querySelector(
                  '#tour-orgCard-Section2DeliveriesPanel #btnExpand-delivery-0'
                )
              ) {
                document
                  .querySelector('#tour-orgCard-Section2DeliveriesPanel #btnExpand-delivery-0')
                  .click();
              }
            }, 200);
            setTimeout(() => {
              if (document.querySelector('#btnEdit-delivery-0')) {
                document.querySelector('#btnEdit-delivery-0').click();
              }
            }, 200);
          }
        },

        {
          id: 'btnGuests',
          title: 'Manage Guest/Power Guest  button',
          text: `Opens View Guest/Power Guest card where you can view Guests and Power Guests.`,
          element: BTN_GUESTS_ID,
          on: RIGHT_START,
        },
        null,
        null,
        500,
        async () => {
          setTimeout(() => {
            if (document.querySelector(BTN_GUESTS_ID)) {
              document.querySelector(BTN_GUESTS_ID).click();
            }
          }, 300);
        }
      )
    );

    dinamycStep.push(
      buildStep(
        tour,
        async () => {
          setTimeout(() => {
            if (document.querySelector('#btnBack')) {
              document.querySelector('#btnBack').click();
            }
          }, 200);
        },
        {
          id: 'gpgContainer',
          title: 'View Guest/Power Guest',
          text: `Guest/Power Guest (by pressing on Edit button, a modal will be open where you can manage Guest/Power Guest access).`,
          element: '.organization-card-container',
          on: RIGHT_START,
          scroll: true,
        },

        null,
        null,
        100,
        async () => {
          if (btnEditGuests) {
            setTimeout(() => {
              if (document.querySelector('#btnEdit')) {
                document.querySelector('#btnEdit').click();
              }
            }, 500);
          }
          setTimeout(() => {
            if (document.querySelector('#btnToggleExcom')) {
              document.querySelector('#btnToggleExcom').click();
            }
          }, 500);
          if (!btnEditGuests) {
            if (document.querySelector('#btnBack')) {
              document.querySelector('#btnBack').click();
            }
          }
        },
        async () => {}
      )
    );

    if (btnEditGuests) {
      if (showExcoms) {
        dinamycStep.push(
          buildStep(
            tour,
            async () => {
              await document.querySelector(BTN_CLOSE_MODAL).click();
              setTimeout(() => {
                if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                  document.querySelector(BTN_CLOSE_MODAL_ID).click();
                }
              }, 100);
            },
            {
              id: 'tour-Excom',
              title: 'Excom Category',
              text: `This is the Excom category, where we can add 
              a person   (or more) in the role of Excom. We can add or change the person in this role by pressing the Edit (pencil symbol)
              button at the right side. Click next to continue.`,
              element: '#tour-orgCard-excom',
              on: RIGHT_START,
              identifierClass: 'Excom',
              scroll: true,
            },
            null,
            null,
            500,
            async () => {},
            async () => {
              await document.querySelector(BTN_CLOSE_MODAL).click();
              setTimeout(() => {
                if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                  document.querySelector(BTN_CLOSE_MODAL_ID).click();
                }
                if (document.querySelector('#btnBack')) {
                  document.querySelector('#btnBack').click();
                }
              }, 100);
            }
          )
        );
      }

      if (showPowerGuests) {
        dinamycStep.push(
          buildStep(
            tour,
            async () => {
              if (!showExcoms) {
                await document.querySelector(BTN_CLOSE_MODAL).click();
                setTimeout(() => {
                  if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                    document.querySelector(BTN_CLOSE_MODAL_ID).click();
                  }
                }, 200);
              }
            },

            {
              id: 'tour-PowerGuest',
              title: 'Power Guest Category',
              text: `This is the Power Guest  category, where we can add 
              a person  (or more) in the role of Power Guest. We can add or change the person in this role by pressing the Edit (pencil symbol)
              button at the right side. Click next to continue.`,
              element: '#tour-orgCard-powerguest',
              on: RIGHT_START,
              identifierClass: 'PowerGuest',
              scroll: true,
            },
            null,
            null,
            500,
            async () => {},
            async () => {
              await document.querySelector(BTN_CLOSE_MODAL).click();
              setTimeout(() => {
                if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                  document.querySelector(BTN_CLOSE_MODAL_ID).click();
                }
                if (document.querySelector('#btnBack')) {
                  document.querySelector('#btnBack').click();
                }
              }, 100);
            }
          )
        );
      }

      if (showGuests) {
        dinamycStep.push(
          buildStep(
            tour,
            null,
            {
              id: 'tour-Guest',
              title: 'Guest Category',
              text: `This is the Guest  category, where we can add 
              a person  (or more) in the role of Guest. We can add or change the person in this role by pressing the Edit (pencil symbol)
              button at the right side. Click next to continue.`,
              element: '#tour-orgCard-guest',
              on: RIGHT_START,
              identifierClass: 'Guest',
              scroll: true,
            },
            null,
            null,
            500,
            async () => {
              await document.querySelector(BTN_CLOSE_MODAL).click();
              setTimeout(() => {
                if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                  document.querySelector(BTN_CLOSE_MODAL_ID).click();
                }
                if (document.querySelector('#btnBack')) {
                  document.querySelector('#btnBack').click();
                }
              }, 200);
            },
            async () => {
              await document.querySelector(BTN_CLOSE_MODAL).click();
              setTimeout(() => {
                if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                  document.querySelector(BTN_CLOSE_MODAL_ID).click();
                }
                if (document.querySelector('#btnBack')) {
                  document.querySelector('#btnBack').click();
                }
              }, 200);
            }
          )
        );
      }
    }
  }

  if (hasBtnExport && !hasBtnEdit) {
    dinamycStep.push(
      buildStep(
        tour,
        async () => {
          if (hasBtnGuests) {
            setTimeout(() => {
              if (document.querySelector(BTN_GUESTS_ID)) {
                document.querySelector(BTN_GUESTS_ID).click();
              }
            }, 100);
            setTimeout(() => {
              if (btnEditGuests) {
                if (document.querySelector('#btnEdit')) {
                  document.querySelector('#btnEdit').click();
                }
              }
            }, 200);
          }
        },
        {
          id: 'btnExport',
          title: 'Export button',
          text: `Allows you to export custom data.`,
          element: BTN_EXPORT_ORG_ID,
          on: RIGHT_START,
        },
        null,
        null,
        500,
        async () => {
          setTimeout(() => {
            if (document.querySelector(BTN_EXPORT_ORG_ID)) {
              document.querySelector(BTN_EXPORT_ORG_ID).click();
            }
          }, 100);
        },
        async () => {
          await document.querySelector(BTN_EXPORT_CLOSE).click();
          setTimeout(() => {
            if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
              document.querySelector(BTN_CLOSE_MODAL_ID).click();
            }
          }, 200);
        }
      )
    );
    dinamycStep.push(
      buildStep(
        tour,
        async () => {
          await document.querySelector(BTN_EXPORT_CLOSE).click();
          setTimeout(() => {
            if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
              document.querySelector(BTN_CLOSE_MODAL_ID).click();
            }
          }, 200);
        },
        {
          id: 'headerExport',
          title: TITLE_EXPORT_OPTIONS,
          text: `Here we have multiple options to choose from that will be exported in the final file. Click next to continue`,
          element: '#tour-header-export',
          on: BOTTOM_START,
        },
        null,
        null,
        500,
        null,
        async () => {
          await document.querySelector(BTN_EXPORT_CLOSE).click();
          setTimeout(() => {
            if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
              document.querySelector(BTN_CLOSE_MODAL_ID).click();
            }
          }, 200);
        }
      )
    );

    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'headerExportList',
          title: TITLE_EXPORT_OPTIONS,
          text: `Here we can select to export persons based on their roles. Whatever we select will be part of the exported file. Click next to continue.`,
          element: '#tour-export-list',
          on: RIGHT_START,
        },
        null,
        null,
        0,
        null,
        async () => {
          await document.querySelector(BTN_EXPORT_CLOSE).click();
          setTimeout(() => {
            if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
              document.querySelector(BTN_CLOSE_MODAL_ID).click();
            }
          }, 200);
        }
      )
    );
    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'headerExportBottom',
          title: TITLE_EXPORT_OPTIONS,
          text: `Disciplines and deliveries, including underlying categories, are available for export. Whatever we select will be part of the exported file. Click next to continue.`,
          element: '#tour-bottom-export',
          on: RIGHT_START,
        },
        null,
        null,
        0,
        null,
        async () => {
          await document.querySelector(BTN_EXPORT_CLOSE).click();
          setTimeout(() => {
            if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
              document.querySelector(BTN_CLOSE_MODAL_ID).click();
            }
          }, 200);
        }
      )
    );
    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'headerExportDropdowns',
          title: TITLE_EXPORT_OPTIONS,
          text: `Here you have the option to select which GBU, BL, and CC to be part of the export. Multiple choices can be selected. Click next to continue.`,
          element: '#tour-dropdowns-export',
          on: BOTTOM_START,
        },
        null,
        null,
        0,
        null,
        async () => {
          await document.querySelector(BTN_EXPORT_CLOSE).click();
          setTimeout(() => {
            if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
              document.querySelector(BTN_CLOSE_MODAL_ID).click();
            }
          }, 200);
        }
      )
    );
    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'headerExportFileName',
          title: TITLE_EXPORT_OPTIONS,
          text: `Here you can enter the name of the file that will be generated. Click next to continue.`,
          element: '#tour-file-name',
          on: BOTTOM_START,
        },
        null,
        null,
        0,
        null,
        async () => {
          await document.querySelector(BTN_EXPORT_CLOSE).click();
          setTimeout(() => {
            if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
              document.querySelector(BTN_CLOSE_MODAL_ID).click();
            }
          }, 200);
        }
      )
    );
    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'headerExportButtons',
          title: TITLE_EXPORT_OPTIONS,
          text: `You can choose to close and exit, or click Export to download the file to your computer. `,
          element: '#export-buttons',
          on: BOTTOM_START,
        },
        null,
        null,
        0,
        async () => {
          await document.querySelector(BTN_EXPORT_CLOSE).click();
          setTimeout(() => {
            if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
              document.querySelector(BTN_CLOSE_MODAL_ID).click();
            }
          }, 200);
        },
        async () => {
          await document.querySelector(BTN_EXPORT_CLOSE).click();
          setTimeout(() => {
            if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
              document.querySelector(BTN_CLOSE_MODAL_ID).click();
            }
          }, 200);
        }
      )
    );
  }
}
