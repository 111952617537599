import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import EmwTypography from '../../../../../../components/EmwTypography/EmwTypography';
import { EmwDropDownList } from '../../../../../../../lib/common';
import EmwDatePicker from '../../../../../../../lib/commonv2/EmwDatePicker';
import { useSelector } from 'react-redux';
import { gbuListSelector, schemaSelector } from '../../../../redux/selectors';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '../../../../constants';
import TourElement from '../../../../../../components/Tour/TourElement/TourElement';

export default function NodeLinkSection({ item, index, isNew, addNodeLink, isCC, setHasBlValue }) {
  const schema = useSelector(schemaSelector);
  const gbuList = useSelector(gbuListSelector);
  const selectedGBU = schema.path.selectedGBU.code;
  const [gbuValue, setGbuValue] = useState(item.gbuCode || selectedGBU);
  const [blValue, setBlValue] = useState(item.blCode || '');
  const [gbuOptions, setGbuOptions] = useState([]);
  const [blOptions, setBlOptions] = useState([]);
  const [startDateValue, setStartDateValue] = useState(item.startDate || null);
  const [endDateValue, setEndDateValue] = useState(item.endDate || null);
  const filteredGbuOptions = gbuList.filter(gbu => gbu.code !== selectedGBU);

  useEffect(() => {
    const _gbuOptions = isCC ? gbuList : filteredGbuOptions;

    setGbuOptions(() => {
      return _gbuOptions.map(gbu => ({ value: gbu.code, text: gbu.code }));
    });

    setGbuValue(item.gbuCode);
    setBlValue(item.blCode);
  }, []);

  useEffect(() => {
    if (!gbuValue) {
      return;
    }
    if (isNew) {
      setBlValue('');
      setHasBlValue(false);
    } else {
      setHasBlValue(true);
    }

    setBlOptions(() => {
      const regExp = /^(.*)-([a-zA-Z]{2})$/;
      let filteredGbuValue = gbuValue;
      if (regExp.test(gbuValue)) {
        filteredGbuValue = regExp.exec(gbuValue)[1];
      }

      const selectedGbu = gbuList.find(gbu => gbu.code === filteredGbuValue);

      return selectedGbu.childs.map(bl => ({ value: bl.code, text: bl.code }));
    });
  }, [gbuValue]);

  const handleGbuChange = value => {
    setGbuValue(value);
    addNodeLink(index, 'gbuCode', value);
  };
  const handleBlChange = value => {
    setBlValue(value);
    addNodeLink(index, 'blCode', value);
    setHasBlValue(true);
  };

  const handleStartDateChange = date => {
    const formattedDate = moment(date).format(DATE_TIME_FORMAT);
    setStartDateValue(formattedDate);
    addNodeLink(index, 'startDate', formattedDate);
  };
  const handleEndDateChange = date => {
    const formattedDate = moment(date).format(DATE_TIME_FORMAT);
    setEndDateValue(formattedDate);
    addNodeLink(index, 'endDate', formattedDate);
  };

  return (
    <TourElement id="allocateGBU">
      <Grid
        item
        className="flex manage-cc-hosting-org-node justify-between pb-s items-center bg-bluegrey-25 rounded-md pt-xs px-xs"
      >
        <Grid item className="manage-cc-hosting-gbu">
          <EmwTypography fontSize="8">{isCC ? 'GBU' : 'New GBU'}</EmwTypography>
          <EmwDropDownList
            placeHolder="Select option"
            options={gbuOptions}
            onDropDownListChanged={e => handleGbuChange(e.target.value)}
            disabled={isCC ? !isNew : false}
            value={gbuValue}
            placement="bottom"
            inputId="GBU"
            size="small"
          />
        </Grid>
        {isCC && (
          <Grid item className="manage-cc-hosting-bl">
            <EmwTypography fontSize="8">BL</EmwTypography>
            <EmwDropDownList
              placeHolder="Select option"
              options={blOptions}
              onDropDownListChanged={e => handleBlChange(e.target.value)}
              disabled={!isNew}
              value={blValue}
              placement="bottom"
              inputId="BL"
              size="small"
            />
          </Grid>
        )}

        <Grid item className="manage-cc-hosting-start date">
          <EmwTypography fontSize="8">Start Date</EmwTypography>
          <EmwDatePicker
            inputId="startDate"
            date={startDateValue}
            handleChange={handleStartDateChange}
          />
        </Grid>
        {isCC && (
          <Grid item className="manage-cc-hosting-end-date">
            <EmwTypography fontSize="8">End Date</EmwTypography>
            <EmwDatePicker
              inputId="endDate"
              date={endDateValue}
              handleChange={handleEndDateChange}
            />
          </Grid>
        )}
      </Grid>
    </TourElement>
  );
}
