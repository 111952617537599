import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNotification } from '../../header/redux/actions';
import { notificationsSelector, userSelector } from '../../header/redux/selectors';
import { Box, Container, Grid } from '@mui/material';
import { StyledLandingPage } from './styled';
import Welcome from './Welcome/Welcome';
import EmwProgressCircle from '../../../components/EmwProgressCircle/EmwProgressCircle';
import NotificationBox from './NotificationBox/NotificationBox';
import CardList from './CardList/CardList';
import { USER_ACTIVITY_SERVICES } from '../../userActivity/constants';
import { userActivity } from '../../userActivity/redux/actions';
import { v4 as uuidv4 } from 'uuid';
import { feedbackOutsideModal, searchFeedbackActive } from '../../feedback/redux/actions';

export default function LandingPage() {
  const dispatch = useDispatch();
  const notifications = useSelector(notificationsSelector);
  const user = useSelector(userSelector);

  useEffect(() => {
    dispatch(feedbackOutsideModal(true));
    dispatch(searchFeedbackActive(false));
    dispatch(getNotification());
    dispatch(
      userActivity({
        uniqueId: uuidv4(),
        service: USER_ACTIVITY_SERVICES.LANDING_PAGE,
        details: 'VIEW',
        uri: '',
      })
    );
    document.getElementById('main').style.backgroundColor = 'rgb(255, 255, 255)';
  }, []);

  if (!user.list.hasOwnProperty('data')) {
    return (
      <Container maxWidth={'xl'}>
        <Box mt={5}>
          <EmwProgressCircle classes={['mx-auto']} />
        </Box>
      </Container>
    );
  }

  return (
    <>
      <Container maxWidth={'xl'}>
        <StyledLandingPage container alignItems="center" className="landingPage" id="landingPage">
          <Grid item sm={12} className="heading">
            <Grid container direction="column" alignItems="center" className="user-info">
              <Box mt={3} className="step-demo">
                {user.list.hasOwnProperty('data') && <Welcome user={user.list.data} />}
              </Box>
              <div style={{ height: '146px' }}>
                <NotificationBox notifications={notifications} />
              </div>
            </Grid>
          </Grid>
          <Grid item sm={12} className="cards">
            <CardList />
          </Grid>
        </StyledLandingPage>
      </Container>
    </>
  );
}
