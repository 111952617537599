import React from 'react';
import { List, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css';
import GeneralFilterListItem from '../GeneralFilterListItem/GeneralFilterListItem';
import { SELECT_ALL_ID } from '../../hooks/useGeneralFilters';

export default function GeneralFiltersFilterList({ type, items }) {
  const rowRenderer = ({ key, index, style }) => (
    <GeneralFilterListItem key={key} type={type} item={items[index]} style={style} />
  );

  return (
    <AutoSizer className="mt-xs">
      {({ height, width }) => (
        <List
          width={width}
          height={142}
          rowCount={items.length}
          rowHeight={22}
          rowRenderer={rowRenderer}
        />
      )}
    </AutoSizer>
  );
}
