import React, { useEffect, useMemo } from 'react';
import useUser from '../../../auth/useUser';
import { Navigate, useLocation } from 'react-router-dom-v5-compat';
import { ADMINISTRATION_PAGES } from '../constants';
import AdminTools from './AdminTools/AdminTools';
import EngineeringCompliance from './EngineeringCompliance/EngineeringCompliance';
import { setIsDrilled, setSelectedReport } from '../../powerBi/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import UsageMonitoring from './UsageMonitoring/components/UsageMonitoring';
import { feedbackOutsideModal, searchFeedbackActive } from '../../feedback/redux/actions';
import { USER_ACTIVITY_SERVICES } from '../../userActivity/constants';
import { userActivity } from '../../userActivity/redux/actions';
import { v4 as uuidv4 } from 'uuid';

export default function Administration() {
  const user = useUser();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(setSelectedReport(null));
    dispatch(setIsDrilled(false));
  }, []);

  return useMemo(() => {
    // if (!user.showAdminPage) {
    //   return null;
    // }
    if (pathname === ADMINISTRATION_PAGES[0].path) {
      dispatch(feedbackOutsideModal(false));
      return <AdminTools user={user} />;
    }

    if (pathname === ADMINISTRATION_PAGES[1].path) {
      dispatch(feedbackOutsideModal(true));
      dispatch(searchFeedbackActive(false));
      dispatch(
        userActivity({
          uniqueId: uuidv4(),
          service: USER_ACTIVITY_SERVICES.USAGE_MONITORING,
          details: 'VIEW',
          uri: '',
        })
      );
      return <UsageMonitoring />;
    }

    if (pathname === ADMINISTRATION_PAGES[2].path) {
      dispatch(feedbackOutsideModal(false));
      return <EngineeringCompliance user={user} />;
    }

    return <Navigate to={ADMINISTRATION_PAGES[0].path} replace={true} />;
  }, [user.showAdminPage, pathname]);
}
