import deepdash from 'deepdash';
import _, { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import { orgDetailsSelector } from '../../features/organization/redux/selectors';

deepdash(_);

const useUtils = () => {
  const { list } = useSelector(orgDetailsSelector);

  const getEngineersCategorySection = (section, type) => {
    let sectionItem;

    _.mapKeysDeep(list.data[section].engineersCategories, (value, key, parentObject) => {
      if (key === 'type' && value === type) {
        sectionItem = cloneDeep(parentObject);
      }
    });

    return {
      section: sectionItem,
      viewMode: sectionItem.viewMode,
      editMode: sectionItem.editMode,
      engineers: sectionItem.engineers,
    };
  };

  /**
   * Should we use section?
   *
   * @param section
   * @param type
   * @param mode
   * @param prefix
   * @returns {`${string}-${string}-${string}`}
   */
  const getIdFor = (section, type, mode, prefix) => {
    return `#${prefix}-${mode}-${type}`;
  };

  return {
    getEngineersCategorySection,
    getIdFor,
  };
};

export default useUtils;
