import { buildStep } from '../tour';
import { RIGHT_START, BTN_GUESTS_ID, BTN_CLOSE_MODAL, BTN_CLOSE_MODAL_ID } from '../constants';

export const buildCountrySteps = (
  dinamycStep,
  tour,
  orgType,
  name,
  hasBtnWarning,
  btnWarningsTitle,
  hasBtnFavorite,
  btnFavoriteTitle,
  hasBtnAllocateCC,
  text,
  hasBtnGuests,
  btnManageGPGTitle,
  hasBtnAddNewCC,
  hasBtnEdit,
  hasHc,
  hasHcDeclared,
  hasCountries,
  hasEms,
  hasHoEOs,
  hasCers,
  hasDeliveries,
  hasDisciplines,
  hasBtnEditEnabled,
  btnEEI,
  btnEditGuests,
  list,
  canAddDiscipline,
  canEditDelivery,
  showExcoms,
  showPowerGuests,
  showGuests
) => {
  dinamycStep.push(
    buildStep(tour, null, {
      id: 'organization-diagram-nodes',
      title: 'Country Column',
      text: `This column shows all the Countries available. In this example ${name} has been selected, revealing all the CBU’s that are part of it.
    The nodes that have a darker gray colour represent "Intuitive Path" - they lead to nodes that are under your responsibility. 
    The nodes that have a star symbol are under your responsibility.
    Click Next to continue.`,
      element: '.tour-organization-diagram-nodes',
      on: RIGHT_START,
      scroll: true,
    })
  );

  dinamycStep.push(
    buildStep(tour, null, {
      id: 'orgCard',
      title: 'Engineering Organization Card',
      text: `This is the Engineering Organization Card, where you can visualise an organization previously selected in the graph. Here you can notice the name of the organization on top, and the start date.  In the next steps we will go into more detail about several functionalities of this area.`,
      element: '.organization-card-container',
      on: RIGHT_START,
    })
  );

  if (hasBtnWarning && hasBtnEdit && hasBtnEditEnabled) {
    dinamycStep.push(
      buildStep(tour, null, {
        id: 'btnWarnings',
        title: btnWarningsTitle,
        text: `Alerts (highlights missing items in your organization).`,
        element: '#btnWarnings',
        on: RIGHT_START,
      })
    );
  }

  if (hasBtnFavorite) {
    dinamycStep.push(
      buildStep(tour, null, {
        id: 'btnFavorite',
        title: btnFavoriteTitle,
        text: `Add to Favorites (will add/remove selected card to your favorites list).`,
        element: '#btnFavorite',
        on: RIGHT_START,
      })
    );
  }

  if (hasBtnAllocateCC) {
    dinamycStep.push(
      buildStep(tour, null, {
        id: 'btnAllocateCC',
        title: text,
        text: `Allocate CC (allocates CC to other organization).`,
        element: '#btnAllocateCC',
        on: RIGHT_START,
      })
    );
  }
  if (hasBtnGuests) {
    dinamycStep.push(
      buildStep(tour, null, {
        id: 'btnGuests',
        title: btnManageGPGTitle,
        text: `Guest/Power Guest (opens a modal where you can manage Guest/Power Guest access).`,
        element: BTN_GUESTS_ID,
        on: RIGHT_START,
      })
    );
  }
  if (hasBtnAddNewCC) {
    dinamycStep.push(
      buildStep(tour, null, {
        id: 'btnAddNewOrganization',
        title: text,
        text: `New organization (creates new organization).`,
        element: '#btnAddNewOrganization',
        on: RIGHT_START,
      })
    );
  }

  //TODO: Gianina Display this button only for the five countries have the button
  // if (btnEEI) {
  //   dinamycStep.push(
  //     buildStep(tour, null, {
  //       id: "btnEEI",
  //       title: "Upload/Download Kpi's data button",
  //       text: `Allows you to export custom data.`,
  //       element: "#btn-KPI",
  //       on: "right-start",
  //     })
  //   );
  // }

  if (hasBtnEdit) {
    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'btnEdit',
          title: text,
          text: `Edit  (make changes to the Engineering Organization Card).`,
          element: '.tour-edit',
          on: RIGHT_START,
        },
        null,
        null,
        100,
        null
      )
    );
  }

  if (hasHc || hasHcDeclared) {
    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'headcount',
          title: 'Engineering Organization Card Info Area',
          text: `In this area you can visualise information related to the selected organization, such as head count
      (calculated, declared), you can view what countries the organization belongs to, CC Type, and Industries it belongs to.`,
          element: '.header-elements',
          on: RIGHT_START,
        },
        null,
        null,
        100,
        null
      )
    );
  }

  if (hasEms && hasHoEOs) {
    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'ems',
          title: 'Engineering Organization Card Management Area',
          text: `In this part you can view the people that are working in specific positions, such as:
          <li>Engineering Manager</li>
           <li>Head of Engineering Operations</li>
          <li>KPI Steward </li>
          <li>Data Referent</li>
          <li>Cybersecurity Referent</li>
          <li>Ecodesign Referent</li>
          <li>UX Design Referent</li>
          <li>Data Protection Referent</li>
          <li>Make or Buy & Footprint Referent</li>
          <li>Engineering Quality Referent</li>
      The people in this list can be changed by clicking “Edit” in the top right corner.`,
          element: '.organization-card-section-1',
          on: RIGHT_START,
          scroll: true,
        },
        null,
        null,
        100,
        null
      )
    );
  }

  // if (hasCers) {
  //   dinamycStep.push(
  //     buildStep(
  //       tour,
  //       null,
  //       {
  //         id: "sectionCer",
  //         title: "Country Engineering Representative",
  //         text: `In this part you can view the Country Engineering Representative.`,
  //         element: ".tour-cers",
  //         on: "right-start",
  //       },
  //       null,
  //       null,
  //       100,
  //       null
  //     )
  //   );
  // }

  if (hasDeliveries) {
    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'hasDeliveries',
          title: 'Engineering Organization Card Delivery Area',
          text: `In this part you can view the deliveries, with sub categories such as solutions/projects, and you can see people in charge of each category. 
      You can collapse lists and view accordingly.`,
          element: '.tour-deliveries-lists',
          on: RIGHT_START,
          scroll: true,
        },
        null,
        null,
        100,
        null
      )
    );
  }
  if (hasDisciplines) {
    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'hasDisciplines',
          title: 'Engineering Organization Card Disciplines Area',
          text: `In this part you can view the disciplines and you can see people in charge of each discipline. 
      You can expand lists and view accordingly.`,
          element: '.tour-disciplines-lists',
          on: RIGHT_START,
          scroll: true,
        },
        null,
        null,
        100,
        null
      )
    );
  }
  if (hasBtnEdit) {
    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'btnEdit',
          title: 'Edit button',
          text: `Edit  (make changes to the Engineering Organization Card).`,
          element: '.tour-edit',
          on: RIGHT_START,
        },
        null,
        null,
        100,
        async () => {
          setTimeout(() => {
            if (hasBtnEdit && hasBtnEditEnabled && document.querySelector('#btnEdit')) {
              document.querySelector('#btnEdit').click();
            }
          }, 200);
        }
      )
    );
  }

  if (hasBtnGuests && !hasBtnEdit) {
    dinamycStep.push(
      buildStep(
        tour,
        async () => {
          if (list.data.section1.btnAlocateCC.visible && list.data.section1.btnAlocateCC.enabled) {
            setTimeout(() => document.querySelector('#btnAllocateCC').click(), 300);
          }
          if (!hasBtnAllocateCC) {
            setTimeout(() => {
              if (document.querySelector('#btnEdit')) {
                document.querySelector('#btnEdit').click();
              }
            }, 100);
          }
          if (!hasBtnAllocateCC && hasDisciplines && canAddDiscipline) {
            if (document.querySelector('#btnEdit')) {
              await document.querySelector('#btnEdit').click();
            }
            setTimeout(() => {
              if (
                document.querySelector(
                  '#tour-orgCard-Section3DisciplinesPanel #btnExpandDisciplines'
                )
              ) {
                document
                  .querySelector('#tour-orgCard-Section3DisciplinesPanel #btnExpandDisciplines')
                  .click();
              }
            }, 500);
          }
          if (!hasBtnAllocateCC && canEditDelivery && !hasDisciplines) {
            setTimeout(() => {
              if (
                document.querySelector(
                  '#tour-orgCard-Section2DeliveriesPanel #btnEditViewExpandDeliveries'
                )
              ) {
                document
                  .querySelector(
                    '#tour-orgCard-Section2DeliveriesPanel #btnEditViewExpandDeliveries'
                  )
                  .click();
              }
            }, 200);
            setTimeout(() => {
              if (
                document.querySelector(
                  '#tour-orgCard-Section2DeliveriesPanel #btnExpand-delivery-0'
                )
              ) {
                document
                  .querySelector('#tour-orgCard-Section2DeliveriesPanel #btnExpand-delivery-0')
                  .click();
              }
            }, 200);
            setTimeout(() => {
              if (document.querySelector('#btnEdit-delivery-0')) {
                document.querySelector('#btnEdit-delivery-0').click();
              }
            }, 200);
          }
        },

        {
          id: 'btnGuests',
          title: 'Manage Guest/Power Guest  button',
          text: `Opens View Guest/Power Guest card where you can view Guests and Power Guests.`,
          element: BTN_GUESTS_ID,
          on: RIGHT_START,
        },
        null,
        null,
        500,
        async () => {
          setTimeout(() => {
            if (document.querySelector(BTN_GUESTS_ID)) {
              document.querySelector(BTN_GUESTS_ID).click();
            }
          }, 300);
        }
      )
    );

    dinamycStep.push(
      buildStep(
        tour,
        async () => {
          setTimeout(() => {
            if (document.querySelector('#btnBack')) {
              document.querySelector('#btnBack').click();
            }
          }, 200);
        },
        {
          id: 'gpgContainer',
          title: 'View Guest/Power Guest',
          text: `Guest/Power Guest (by pressing on Edit button, a modal will be open where you can manage Guest/Power Guest access).`,
          element: '.organization-card-container',
          on: RIGHT_START,
          scroll: true,
        },

        null,
        null,
        100,
        async () => {
          if (btnEditGuests) {
            setTimeout(() => {
              if (document.querySelector('#btnEdit')) {
                document.querySelector('#btnEdit').click();
              }
            }, 500);
          }
          setTimeout(() => {
            if (document.querySelector('#btnToggleExcom')) {
              document.querySelector('#btnToggleExcom').click();
            }
          }, 500);
          if (!btnEditGuests) {
            if (document.querySelector('#btnBack')) {
              document.querySelector('#btnBack').click();
            }
          }
        },
        async () => {}
      )
    );

    if (btnEditGuests) {
      if (showExcoms) {
        dinamycStep.push(
          buildStep(
            tour,
            async () => {
              await document.querySelector(BTN_CLOSE_MODAL).click();
              setTimeout(() => {
                if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                  document.querySelector(BTN_CLOSE_MODAL_ID).click();
                }
              }, 100);
            },
            {
              id: 'tour-Excom',
              title: 'Excom Category',
              text: `This is the Excom category, where we can add 
              a person   (or more) in the role of Excom. We can add or change the person in this role by pressing the Edit (pencil symbol)
              button at the right side. Click next to continue.`,
              element: '#tour-orgCard-excom',
              on: RIGHT_START,
              identifierClass: 'Excom',
              scroll: true,
            },
            null,
            null,
            500,
            async () => {},
            async () => {
              await document.querySelector(BTN_CLOSE_MODAL).click();
              setTimeout(() => {
                if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                  document.querySelector(BTN_CLOSE_MODAL_ID).click();
                }
                if (document.querySelector('#btnBack')) {
                  document.querySelector('#btnBack').click();
                }
              }, 100);
            }
          )
        );
      }

      if (showPowerGuests) {
        dinamycStep.push(
          buildStep(
            tour,
            async () => {
              if (!showExcoms) {
                await document.querySelector(BTN_CLOSE_MODAL).click();
                setTimeout(() => {
                  if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                    document.querySelector(BTN_CLOSE_MODAL_ID).click();
                  }
                }, 200);
              }
            },

            {
              id: 'tour-PowerGuest',
              title: 'Power Guest Category',
              text: `This is the Power Guest  category, where we can add 
              a person  (or more) in the role of Power Guest. We can add or change the person in this role by pressing the Edit (pencil symbol)
              button at the right side. Click next to continue.`,
              element: '#tour-orgCard-powerguest',
              on: RIGHT_START,
              identifierClass: 'PowerGuest',
              scroll: true,
            },
            null,
            null,
            500,
            async () => {},
            async () => {
              await document.querySelector(BTN_CLOSE_MODAL).click();
              setTimeout(() => {
                if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                  document.querySelector(BTN_CLOSE_MODAL_ID).click();
                }
                if (document.querySelector('#btnBack')) {
                  document.querySelector('#btnBack').click();
                }
              }, 100);
            }
          )
        );
      }

      if (showGuests) {
        dinamycStep.push(
          buildStep(
            tour,
            null,
            {
              id: 'tour-Guest',
              title: 'Guest Category',
              text: `This is the Guest  category, where we can add 
              a person  (or more) in the role of Guest. We can add or change the person in this role by pressing the Edit (pencil symbol)
              button at the right side. Click next to continue.`,
              element: '#tour-orgCard-guest',
              on: RIGHT_START,
              identifierClass: 'Guest',
              scroll: true,
            },
            null,
            null,
            500,
            async () => {
              await document.querySelector(BTN_CLOSE_MODAL).click();
              setTimeout(() => {
                if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                  document.querySelector(BTN_CLOSE_MODAL_ID).click();
                }
                if (document.querySelector('#btnBack')) {
                  document.querySelector('#btnBack').click();
                }
              }, 200);
            },
            async () => {
              await document.querySelector(BTN_CLOSE_MODAL).click();
              setTimeout(() => {
                if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                  document.querySelector(BTN_CLOSE_MODAL_ID).click();
                }
                if (document.querySelector('#btnBack')) {
                  document.querySelector('#btnBack').click();
                }
              }, 200);
            }
          )
        );
      }
    }
  }
};
