const COLORS = {
  DARK_BLUE: '#0F186E',
  LIGHT_BLUE: '#6871BF',
  DARK_GREEN: '#126836',
  LIGHT_GREEN: '#178244',
  SOFT_ORANGE: '#F3C999',
  LIGHT_ORANGE: '#E69433',
  ORANGE: '#E07900',
};

const info = [
  { text: 'HoD with wrong Job Family', color: COLORS.DARK_BLUE },
  { text: 'Hod with wrong EM', color: COLORS.LIGHT_BLUE },
];

const colorMapping = {
  hodWrongJF: {
    color: COLORS.DARK_BLUE,
  },
  hodWrongEM: {
    color: COLORS.LIGHT_BLUE,
  },
};

export { COLORS, info, colorMapping };
