export const MODULE_NAME = 'userActivity';

export const USER_ACTIVITY_SERVICES = {
  MCD_TILE: 'Mood & Confidence',
  MCD_DRILL_THROUGH: 'Mood & Confidence Drill Through',
  MCD_DRILL_TO: 'Mood & Confidence Drill to details',
  MCD_HZA: 'Mood & Confidence with Project Names',

  ENGINEERING_COCKPIT: 'Engineering Cockpit',
  OPERATING_MODEL: 'Operating Model',
  TALENTS_AND_CULTURE: 'Talents & Culture',
  COMPETITIVENESS: 'Competitiveness',
  WORKERS: 'Workers Assessments - Drill to detail',
  EVOLUTION: 'Skill Sets Yearly Evolution - Drill to detail',
  DISTANCE_AVG: 'Skill Sets Distances & Averages - Drill to detail',
  DISTRIBUTION: 'Skill Distribution - Drill to detail',
  DATA: 'Skill Data - Drill to detail',

  LANDING_PAGE: 'Landing Page',

  ENG_ORG: 'Engineering Organization View',
  ENG_ORG_EDIT: 'Engineering Organization Edit',
  ENG_ORG_EXPORT: 'Engineering Organization Export',
  G_PG: 'Guest/Power Guest View',
  G_PG_EDIT: 'Guest/Power Guest Edit',
  MANAGE_KPI: 'Manage KPI data',
  SEARCH: 'Search User/Organization',
  ALLOCATE_CC: 'Allocate CC',

  USAGE_MONITORING: 'Usage Monitoring',
  USAGE_MONITORING_DRILL_TO: 'Usage Monitoring - Drill to detail',
  USAGE_MONITORING_DRILL_THROUGH: 'Usage Monitoring - Drill Through',

  EMW_ACCESS: 'Emw Access',
};
