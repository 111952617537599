import React from 'react';
import { styled } from '@mui/material/styles';
import { QtmDropdownSelect, QtmTypography, QtmDropdownSelectOption } from '@qtm/react';

const Root = styled('div')(() => ({
  display: 'flex',
}));

export default function EmwDropDownList(props) {
  const {
    onDropDownListChanged,
    options,
    colors,
    placeHolder,
    value,
    inputId,
    disabled,
    additionalCss,
    placement,
    size,
  } = props;

  const onClickOption = event => {
    if (typeof onDropDownListChanged === 'function') {
      onDropDownListChanged(event, inputId);
    }
  };

  if (options && options.length > 0) {
    return (
      <Root key={inputId + 1000000}>
        <QtmDropdownSelect
          key={'cheie'}
          placement={placement ? placement : 'top'}
          value={value}
          placeholder={placeHolder}
          inputId={inputId}
          disabled={disabled}
          style={additionalCss}
          size={size ? size : 'medium'}
        >
          <div>
            {options.map((el, index) => {
              return (
                <QtmDropdownSelectOption
                  key={index}
                  value={el.value}
                  title={el.info ? el.info : ''}
                  onClickOption={onClickOption}
                  classes={el.disabled ? 'pointer-events-none' : ''}
                >
                  <QtmTypography
                    classes={el.disabled ? 'text-bluegrey-200 cursor-not-allowed' : ''}
                    component="caption-1"
                  >
                    {el.text}
                  </QtmTypography>
                </QtmDropdownSelectOption>
              );
            })}
          </div>
        </QtmDropdownSelect>
      </Root>
    );
  }

  return null;
}
