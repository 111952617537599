import { createAction } from '@reduxjs/toolkit';

export const FEEDBACK_STATUS = 'feedback_status';
export const FEEDBACK_INSIDE_MODAL = 'feedback_inside_modal';
export const FEEDBACK_OUTSIDE_MODAL = 'feedback_outside_modal';
export const SEARCH_ACTIVE = 'search_active';

export const feedbackInsideModal = createAction(FEEDBACK_INSIDE_MODAL);
export const feedbackOutsideModal = createAction(FEEDBACK_OUTSIDE_MODAL);
export const searchFeedbackActive = createAction(SEARCH_ACTIVE);
export const feedbackStatus = createAction(FEEDBACK_STATUS);
