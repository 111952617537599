import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { VIEW_MODE } from '../EngCategories';

export const StyledEngCategoryPanel = styled(Grid, {
  shouldForwardProp: engCategory => engCategory,
})(({ engCategory, viewMode }) => {
  let styled = {};

  if (viewMode === VIEW_MODE.VIEW) {
    if (engCategory.viewMode.footer.showDivider) {
      styled = {
        ...styled,
        borderBottom: '2px solid #041295',
        padding: '8px 0 8px 0',
      };
    }

    // if (true || engCategory.viewMode.header.showDivider) {
    //   styled = {
    //     ...styled,
    //     borderTop: '3px solid #bcbece',
    //     padding: '8px 0 8px 0',
    //   };
    // }
  }

  if (viewMode === VIEW_MODE.EDIT) {
    styled = {
      ...styled,
      // padding: '4px 0 0 0',
      '.organization-card-section-head': {
        height: '50px',
        marginBottom: '8px',
      },
      '.MuiCollapse-root': {
        '.child': {
          '.panel-item': {
            margin: '0 0 8px 0',
          },
        },
      },
    };

    if (engCategory.childCategories.length) {
      styled = {
        ...styled,
        '.MuiCollapse-wrapperInner': {
          '.organization-card-section-head,  .child': {
            marginLeft: '8px',
          },
        },
      };
    }

    if (engCategory.editMode.footer.showDivider) {
      styled = {
        ...styled,
        borderBottom: '2px solid rgba(0, 0, 0, 0.12)',
        padding: '8px 0 8px 0',
      };
    }
  }

  return styled;
});
