import { styled } from '@mui/material/styles';

export const StyledEmwGeneralFilters = styled('div')(({ theme }) => {
  const GRAY_BORDER = '1px solid #B3B0AD';
  const SCALE = 'scale(-1, 1)';
  return {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    input: {
      border: 'none',
    },

    '.generalFilters__toggleHeader': {
      cursor: 'pointer',
    },

    '.selected-filter-info': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    '.isSelected': {
      backgroundColor: '#f3f2f1',
    },

    '.listItem': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    '.generalFilters__mainSearch': {
      border: GRAY_BORDER,
      borderRadius: '2px',

      input: {
        width: '100%',
        height: '29px',
        fontSize: '12px',
        '&:focus': {
          outline: 'none',
        },
      },
    },

    '.reverse-mirror': {
      '-moz-transform': SCALE,
      '-webkit-transform': SCALE,
      '-o-transform': SCALE,
      '-ms-transform': SCALE,
      transform: SCALE,
    },

    '.generalFilters__filtersWrapper': {
      flexGrow: '1',
      borderTop: GRAY_BORDER,
      '*': {
        color: '#252423!important',
      },
      '.generalFilters__moreOptions': {
        cursor: 'pointer',
        marginRight: '8px',
      },
      '.generalFilters__clearAll': {
        cursor: 'pointer',
        marginRight: '3px',
      },
      '.generalFilters__filterCard': {
        border: GRAY_BORDER,
        padding: '8px 4px 4px 8px',
        borderRadius: '2px',
        cursor: 'pointer',

        select: {
          border: '1px solid black',
          fontSize: '12px',
          height: '25px',
          width: '100%',

          '*': {
            border: '1px solid red',
          },
        },

        i: {
          color: '#B3B0AD!important',
        },

        '.generalFilters__filtertype-search': {
          backgroundColor: '#FFFFFF',
          borderBottom: GRAY_BORDER,
          marginTop: '5px',

          input: {
            fontSize: '12px',
            height: '25px',
            '&:focus': {
              outline: 'none',
            },
            width: '50%',
          },
        },

        '.generalFilters__filterCard__list': {
          overflow: 'hidden',
          '&.visible': {
            height: '225px',
          },
          '&.hidden': {
            height: 0,
          },
        },
      },
    },
  };
});
