import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { perspectiveSelector, schemaSelector } from '../../redux/selectors';
import { StyledDiagram } from './style';
import useLink from './hooks/useLink';
import DiagramHeader from './components/DiagramHeader/DiagramHeader';
import DiagramBody from './components/DiagramBody/DiagramBody';
import { ORGANIZATION_PERSPECTIVE } from '../../constants';

const Diagram = forwardRef(function Diagram({ boxRef }, ref) {
  useLink();
  const perspective = useSelector(perspectiveSelector);
  const schema = useSelector(schemaSelector);

  return (
    <>
      <StyledDiagram
        className={`${
          perspective === ORGANIZATION_PERSPECTIVE.BY_COUNTRY
            ? 'by-country-diagram'
            : 'by-gbu-diagram'
        }`}
        container
        direction="column"
        id="diagram"
        wrap="nowrap"
      >
        <DiagramHeader perspective={perspective} schema={schema} ref={ref} />
        <DiagramBody perspective={perspective} schema={schema} />
      </StyledDiagram>
    </>
  );
});

export default Diagram;
