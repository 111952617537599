import React, { useContext, useEffect, useState } from 'react';
import { EmwButton } from '../../../../../../../lib/common';
import { TdsIcon } from '@tds/react';
import EmwDialog from '../../../../../../components/EmwDialog/EmwDialog';
import { useDispatch, useSelector } from 'react-redux';
import useAppSnackbar from '../../../../../../hooks/useAppSnankbar';
import { schemaSelector, perspectiveSelector } from '../../../../redux/selectors';
import { DiagramContext } from '../../../Diagram/context/DiagramContext';
import * as api from '../../../../redux/api';
import { Collapse, Grid, IconButton } from '@mui/material';
import EmwTypography from '../../../../../../components/EmwTypography/EmwTypography';
import EmwTextField from '../../../../../../../lib/commonv2/EmwTextField';
import { ORGANIZATION_PERSPECTIVE } from '../../../../constants';
import { feedbackInsideModal } from '../../../../../feedback/redux/actions';
import { userActivity } from '../../../../../userActivity/redux/actions';
import { USER_ACTIVITY_SERVICES } from '../../../../../userActivity/constants';
import { v4 as uuidv4 } from 'uuid';
import { StyledManageCCHosting } from './styled';
import PlusIcon from '../../../../../../../lib/icons/PlusIcon';
import NodeLinkSection from './NodeLinkSection';
import TourElement from '../../../../../../components/Tour/TourElement/TourElement';
import { getTreeData } from '../../../../../header/redux/actions';

export default function ManageOrgHosting({ btn, data, name, setName, orgType, cardName }) {
  const snackbar = useAppSnackbar();
  const schema = useSelector(schemaSelector);
  const perspective = useSelector(perspectiveSelector);
  const diagramContext = useContext(DiagramContext);
  const orgLinks = data.cardDetails.orgLinks;
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [isSectionOpen, setIsSectionOpen] = useState(true);
  const [nodeLinks, setNodeLinks] = useState([]);
  const [nodeLinksList, setNodeLinksList] = useState([]);
  const isCC = orgType === 'CC';
  const btnPlaceholder = isCC ? 'Manage CC Hosting' : 'Move';
  const dialogTitle = isCC ? 'Manage CC Hosting' : `Move ${cardName}`;
  const currentGbuCode = schema.path.selectedGBU.code;
  const [hasBlValue, setHasBlValue] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    dispatch(
      userActivity({
        uniqueId: uuidv4(),
        service: USER_ACTIVITY_SERVICES.ALLOCATE_CC,
        details: 'VIEW',
        uri: '',
      })
    );
    setNodeLinksList(orgLinks);

    return () => {
      setNodeLinksList([]);
    };
  }, [isOpen]);

  useEffect(() => {
    if (!nodeLinksList) {
      return;
    }
    const _nodeLinks = [];
    nodeLinksList.forEach((item, index) => {
      _nodeLinks.push(
        <div className="node-link-section justify-between pb-xs">
          <NodeLinkSection
            isNew={item.isNew ? true : false}
            addNodeLink={addNodeLink}
            index={index}
            item={item}
            isCC={isCC}
            setHasBlValue={setHasBlValue}
          />
        </div>
      );
    });
    setNodeLinks(_nodeLinks);
  }, [nodeLinksList, isOpen]);

  const toggle = () => {
    dispatch(feedbackInsideModal(true));
    setIsOpen(!isOpen);
  };

  const addNodeLink = (index, type, value) => {
    const _nodeLinksList = nodeLinksList;
    _nodeLinksList[index][type] = value;
  };

  const addItem = () => {
    const _nodeLinksList = [];

    _nodeLinksList.push({
      isNew: true,
      gbuCode: '',
      blCode: '',
      startDate: '',
      endDate: '',
    });
    setNodeLinksList(prevNodeLinksList => [...prevNodeLinksList, _nodeLinksList[0]]);
  };

  const saveHandler = async () => {
    if (!isCC) {
      const oldNode = {
        gbuCode: currentGbuCode,
        blCode: null,
        startDate: null,
        endDate: nodeLinksList[0].startDate,
      };
      nodeLinksList.unshift(oldNode);
    }

    const nodeLinksForPayload = nodeLinksList
      .map(obj => {
        const { isNew, ...rest } = obj;
        return rest;
      })
      .filter(item => item.gbuCode !== '' || item.blCode !== '');

    const payload = {
      id: data.cardDetails.id,
      name: name,
      orgLinks: nodeLinksForPayload,
      originBlCode: data.cardDetails.blCode,
      type: orgType,
    };

    try {
      setIsLoading(true);
      const response = await api.allocateOrgApi(payload);
      snackbar.show(response.message, response.messageType);

      if (perspective === ORGANIZATION_PERSPECTIVE.BY_COUNTRY) {
        await diagramContext.fetchAndSelectNode(
          schema.path.selectedBL.id,
          'DOMAIN',
          null,
          null,
          data.cardDetails.parentCountryId
        );
        await diagramContext.fetchAndSelectNode(
          data.cardDetails.id,
          response.data.cardDetails.type,
          data.cardDetails.blCode,
          null,
          data.cardDetails.parentCountryId
        );
      } else {
        if (isCC) {
          const parentGbuId = response.data.cardDetails.parentBusId.length
            ? response.data.cardDetails.parentBusId[0]
            : schema.path.selectedGBU.id;
          const parentBlId = response.data.cardDetails.parentBlsId.length
            ? response.data.cardDetails.parentBlsId[0]
            : schema.path.selectedBL.id;
          await diagramContext.fetchAndSelectNode(parentGbuId, 'GBU');
          await diagramContext.fetchAndSelectNode(parentBlId, 'BL');
          if (response.data.cardDetails.parentBlsCode.length) {
            await diagramContext.fetchAndSelectNode(
              response.data.cardDetails.id,
              'CC',
              response.data.cardDetails.parentBlsCode[0]
            );
          }
        } else {
          //for BL, open the diagram on the new GBU node from the response object
          await diagramContext.fetchAndSelectNode(response.data.cardDetails.parentBusId[0], 'GBU');
          await diagramContext.fetchAndSelectNode(
            data.cardDetails.id,
            response.data.cardDetails.type,
            schema.path.selectedBL.code
          );
        }
      }
      setIsOpen(false);
    } catch (e) {
      snackbar.show(e.statusText, 'error');
      console.error(e);
    } finally {
      dispatch(getTreeData());
      setIsLoading(false);
    }
  };

  const onChange = e => {
    setName(e.currentTarget.value);
  };

  return (
    <Grid item ml={0.5}>
      <EmwButton
        variant="ghost"
        size="small"
        disabled={!btn.enabled}
        title={btnPlaceholder}
        id="btnAllocateCC"
        onClick={toggle}
      >
        <TdsIcon icon="settings" variant="outlined" size="large" />
      </EmwButton>
      {isOpen && (
        <EmwDialog
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          title={dialogTitle}
          actionButton="Save"
          callback={saveHandler}
          icon="settings"
          classes="settings-icon"
          width="600px"
          loading={isLoading}
          disabled={!hasBlValue}
        >
          <StyledManageCCHosting
            container
            className="flex manage-cc-hosting-dialog-content items-center overflow-visible"
          >
            <Grid item>
              <TourElement id="allocateName">
                <Grid item className="flex manage-cc-hosting-name-and-add items-center py-s">
                  <EmwTypography classes={'mr-xs'} fontSize={'7'}>
                    {isCC ? 'Name' : 'Current GBU'}
                  </EmwTypography>
                  <EmwTextField
                    name={isCC ? name : currentGbuCode}
                    value={isCC ? name : currentGbuCode}
                    id="cc_name"
                    onChange={onChange}
                    disabled={!isCC}
                    size="small"
                    classes="manage-cc-hosting-cc-name"
                  />
                </Grid>
              </TourElement>
              {isCC && (
                <Grid
                  item
                  className="manage-cc-hosting-add-section rounded-md items-center justify-center mb-s"
                  id={`btnAddNewCCLink`}
                >
                  <IconButton
                    title="Add new"
                    disableRipple
                    onClick={addItem}
                    id={`btnAddNewCC`}
                    className="items-center ml-20xl"
                  >
                    <PlusIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            <Collapse in={isSectionOpen} sx={{ width: '100%' }} className="collapse">
              {nodeLinks}
            </Collapse>
          </StyledManageCCHosting>
        </EmwDialog>
      )}
    </Grid>
  );
}
