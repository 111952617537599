import React, { useEffect, useState } from 'react';
import { getMonthlyUsersListApi } from '../../../redux/api';
import EmwProgressCircle from '../../../../../components/EmwProgressCircle/EmwProgressCircle';
import EmwWebExcel from '../../../../../components/EmwWebExcel/EmwWebExcel';
import ServicesRolesFilters from './ServicesRolesFilters';
import {
  rolesFiltersSelector,
  servicesFiltersSelector,
} from '../../../../../components/EmwGeneralFilters/redux/selectors';
import { useSelector } from 'react-redux';
import useAppSnackbar from "../../../../../hooks/useAppSnankbar";

export default function UsageMonitoringDrillToDetail({ payload, stateData }) {
  const [data, setData] = useState(null);
  const snackbar = useAppSnackbar();
  const rolesFilters = useSelector(rolesFiltersSelector);
  const servicesFilters = useSelector(servicesFiltersSelector);

  useEffect(() => {
    getMonthlyUsersListApi(payload)
      .then(response => {
        if (response && response.data) {
          setData(response.data);
        }
        if (!response.data) {
          snackbar.show(response.message, 'error');
        }
      })
      .catch(err => {
        console.error('Error getting users list', err);
      });
  }, [stateData]);

  return (
    <>
      <div className="flex bg-white-100 h-6xl items-center mt-s mb-s pl-m shadow-s-light">
        <ServicesRolesFilters stateData={stateData} />
      </div>

      {data ? (
        <EmwWebExcel
          title="Active unique users per period"
          tooltipInfo="Active unique users per selected period(connected users with their connection number & rank per period)"
          data={data}
          isDrillToDetails={true}
          getApi={getMonthlyUsersListApi}
          payload={payload}
          id="drillToDetailsTable"
        />
      ) : (
        <div className="flex items-center justify-center">
          <EmwProgressCircle size="small" />
        </div>
      )}
    </>
  );
}
