import React, { useEffect, useState } from 'react';
import EmwButton from '../../components/EmwButton/EmwButton';
import { StyledFeedbackButton } from './styled';
import { TdsIcon } from '@tds/react';
import FeedbackForm from './FeedbackForm';
import FeedbackSubmitted from './FeedbackSubmitted';
import { Backdrop } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { feedbackStatus, searchFeedbackActive } from './redux/actions';
import { perspectiveSelector, viewSelector } from '../organization/redux/selectors';
import { ORGANIZATION_VIEW } from '../organization/constants';
import { saveUserSatisfaction } from '../organization/redux/api';
import { USER_ACTIVITY_SERVICES } from '../userActivity/constants';
import { searchActiveSelector } from './redux/selectors';

let availableHash = [
  '#workers',
  '#evolution',
  '#distance&avg',
  '#distribution',
  '#data',
  '#drill-to-details',
  '#drill-through',
  '#drilltodetails',
  '#drillthrough',
  '#answers-with-project-names',
];
export default function FeedbackButton({ title }) {
  const currentView = useSelector(viewSelector);
  const [isOpen, setIsOpen] = useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const dispatch = useDispatch();
  const isSearchActive = useSelector(searchActiveSelector);
  const service = formatUrlPath(window.location.pathname);

  const [payload, setPayload] = useState({
    service: service,
    rating: null,
    workedWell: '',
    toBeImproved: '',
  });

  useEffect(() => {
    setPayload({
      service: '',
      rating: null,
      workedWell: '',
      toBeImproved: '',
    });
  }, [service]);

  function formatUrlPath(url) {
    // Extract the path after the last slash
    const segments = url.split('/');
    const path = segments[segments.length - 1];

    let formattedPath;
    switch (path) {
      case 'landingpage':
        formattedPath = USER_ACTIVITY_SERVICES.LANDING_PAGE;
        break;
      case 'organization':
        formattedPath = USER_ACTIVITY_SERVICES.ENG_ORG;

        break;
      case 'moodandconfidence':
        formattedPath = USER_ACTIVITY_SERVICES.MCD_TILE;
        break;

      case 'talents-and-culture':
        formattedPath = USER_ACTIVITY_SERVICES.TALENTS_AND_CULTURE;
        break;
      default:
        formattedPath = path
          .split('-')
          .map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
          })
          .join(' ');
    }
    if (availableHash.includes(window.location.hash)) {
      if (window.location.hash === '#workers') {
        formattedPath = USER_ACTIVITY_SERVICES.WORKERS;
      }
      if (window.location.hash === '#evolution') {
        formattedPath = USER_ACTIVITY_SERVICES.EVOLUTION;
      }
      if (window.location.hash === '#distance&avg') {
        formattedPath = USER_ACTIVITY_SERVICES.DISTANCE_AVG;
      }
      if (window.location.hash === '#distribution') {
        formattedPath = USER_ACTIVITY_SERVICES.DISTRIBUTION;
      }
      if (window.location.hash === '#data') {
        formattedPath = USER_ACTIVITY_SERVICES.DATA;
      }
      if (window.location.hash === '#drill-to-details') {
        formattedPath = USER_ACTIVITY_SERVICES.MCD_DRILL_TO;
      }

      if (window.location.hash === '#drill-through') {
        formattedPath = USER_ACTIVITY_SERVICES.MCD_DRILL_THROUGH;
      }

      if (window.location.hash === '#drilltodetails') {
        formattedPath = USER_ACTIVITY_SERVICES.USAGE_MONITORING_DRILL_TO;
      }

      if (window.location.hash === '#drillthrough') {
        formattedPath = USER_ACTIVITY_SERVICES.USAGE_MONITORING_DRILL_THROUGH;
      }

      if (window.location.hash === '#answers-with-project-names') {
        formattedPath = USER_ACTIVITY_SERVICES.MCD_HZA;
      }
    }
    if (currentView === ORGANIZATION_VIEW.GUEST) {
      formattedPath = USER_ACTIVITY_SERVICES.G_PG;
    }
    if (isSearchActive) {
      formattedPath = USER_ACTIVITY_SERVICES.SEARCH;
    }

    return formattedPath;
  }

  const handleClick = () => {
    dispatch(feedbackStatus(!isOpen));
    setIsOpen(!isOpen);
    setFeedbackSubmitted(false);
    if (isOpen) {
      dispatch(searchFeedbackActive(false));
    }
  };
  const handleClickSubmit = () => {
    setFeedbackSubmitted(!feedbackSubmitted);
    saveUserSatisfaction(payload);
    setPayload({
      service: '',
      rating: null,
      workedWell: '',
      toBeImproved: '',
    });
  };
  const handleClickLeaveAnotherFeedback = () => {
    setFeedbackSubmitted(!feedbackSubmitted);
    setPayload({
      service: '',
      rating: null,
      workedWell: '',
      toBeImproved: '',
    });
  };
  return (
    <StyledFeedbackButton>
      <Backdrop sx={theme => ({ color: '#fff' })} open={isOpen}>
        {isOpen && !feedbackSubmitted && (
          <FeedbackForm
            setPayload={setPayload}
            payload={payload}
            handleClickSubmit={handleClickSubmit}
            handleClick={handleClick}
            service={service}
            title={title}
          />
        )}
        {isOpen && feedbackSubmitted && (
          <FeedbackSubmitted
            handleClick={handleClick}
            handleClickLeaveAnotherFeedback={handleClickLeaveAnotherFeedback}
          />
        )}
      </Backdrop>
      <EmwButton
        classes={`${isOpen ? 'feedback-button-open' : 'feedback-button-closed'}`}
        variant="filled"
        color="primary"
        size="small"
        title={`Leave a feedback`}
        id="btn-feedback"
        onClick={handleClick}
      >
        <TdsIcon icon={`${isOpen ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}`} size="small" />
        <p> Feedback </p>
      </EmwButton>
    </StyledFeedbackButton>
  );
}
