import * as constants from '../constants';
import { createAction } from '@reduxjs/toolkit';

export const FILTERS = `${constants.MODULE_NAME}/FILTERS`;
export const SELECTED_FILTERS = `${constants.MODULE_NAME}/SELECTED_FILTERS`;
export const CLEAR_ALL_FILTERS = `${constants.MODULE_NAME}/CLEAR_ALL_FILTERS`;
export const AVAILABLE_FILTERS = `${constants.MODULE_NAME}/AVAILABLE_FILTERS`;
export const LOADING = `${constants.MODULE_NAME}/LOADING`;
export const ROLES_FILTERS = `${constants.MODULE_NAME}/ROLES_FILTERS`;
export const SERVICES_FILTERS = `${constants.MODULE_NAME}/SERVICES_FILTERS`;

export const SELECTED_DRILL_TO_DETAIL_FILTERS = `${constants.MODULE_NAME}/SELECTED_DRILL_TO_DETAIL_FILTERS`;
export const AVAILABLE_DRILL_TO_DETAIL_FILTERS = `${constants.MODULE_NAME}/AVAILABLE_DRILL_TO_DETAIL_FILTERS`;
export const DRILL_TO_DETAIL_FILTERS = `${constants.MODULE_NAME}/DRILL_TO_DETAIL_FILTERS`;

export const setGeneralFilters = createAction(FILTERS);
export const setSelectedFilters = createAction(SELECTED_FILTERS);
export const clearAllFilters = createAction(CLEAR_ALL_FILTERS);
export const setAvailableFilters = createAction(AVAILABLE_FILTERS);
export const setLoading = createAction(LOADING);
export const rolesFilters = createAction(ROLES_FILTERS);
export const servicesFilters = createAction(SERVICES_FILTERS);

export const setGeneralDrillToDetailFilters = createAction(DRILL_TO_DETAIL_FILTERS);
export const setSelectedDrillToDetailFilters = createAction(SELECTED_DRILL_TO_DETAIL_FILTERS);
export const setAvailableDrillToDetailFilters = createAction(AVAILABLE_DRILL_TO_DETAIL_FILTERS);
