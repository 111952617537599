import React from 'react';
import EngCategoryPanel from './EngCategoryPanel';
import EngCategoryList from './EngCategoryList';

export default function EngCategory({ engCategory, viewMode, targetField, section, categories }) {
  if (engCategory[viewMode].header.visible) {
    return (
      <EngCategoryPanel
        engCategory={engCategory}
        viewMode={viewMode}
        targetField={targetField}
        section={section}
        categories={categories}
      />
    );
  }

  return (
    <EngCategoryList
      engCategory={engCategory}
      engineers={engCategory.engineers}
      isParent={true}
      viewMode={viewMode}
      engineersOnEachLine={engCategory.viewMode.engineersOnEachLine}
    />
  );
}
