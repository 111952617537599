import React, { useEffect, useState, useRef, useContext } from 'react';
import { Grid, DialogContent, DialogTitle, DialogActions, Divider } from '@mui/material';
import { TdsProgressCircle, TdsIcon } from '@tds/react';
import moment from 'moment';
import Handsontable from 'handsontable';
import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.min.css';
import { EmwButton } from '../../../lib/common';
import EmwTypography from '../EmwTypography/EmwTypography';
import EmwRichTextFormatting from './EmwRichTextFormatting';
import { StyledEmwHotTable, StyledEmwHotSheetsWrapper } from './styled';
import EmwFooterEnvInfo from '../EmwFooterEnvInfo/EmwFooterEnvInfo';
import KPIDownloadButton from '../../features/organization/components/OrganizationCard/components/ExportKPI/KPIDownloadButton';
import KPIReadMeTooltip from '../../features/organization/components/OrganizationCard/components/ExportKPI/KPIReadMeTooltip';
import EEIFormInfo from '../../features/organization/components/OrganizationCard/components/ExportKPI/EEIFormInfo';
import { formatColumns } from '../../features/organization/components/OrganizationCard/components/ExportKPI/EEIFormColumns';
import { HzaContext } from '../../features/hzaSync/context/HzaContext';
import { computeURL, fetchOptions } from '../../../util/fetch';
import WFBFormSheets from '../../features/organization/components/OrganizationCard/components/ExportKPI/WFBFormSheets';
import { getNestedHeaders } from '../../features/organization/components/OrganizationCard/components/ExportKPI/WFBFormColumns';
import EmwProgressCircle from '../EmwProgressCircle/EmwProgressCircle';
import { cloneDeep } from 'lodash';

// register Handsontable's modules
registerAllModules();

export default function EmwHotTable({
  id,
  columns,
  isCcForm,
  getApi,
  saveApi,
  isOpen,
  setIsOpen,
  orgId,
  type,
  ccExcelFormExport,
  handleUpload,
  selectedYear,
  selectedKPI,
  regionName,
  selectedOption,
  errorComments,
  getKPIErrors,
  wfbHotSettings,
  loadWFBData,
  populateTable,
  getRowsWithErrors,
  getRowWithErrorIndex,
  getColsWithError,
  getErrorCommentValue,
  handleDelete,
  payloadArgs,
}) {
  const hzaContext = useContext(HzaContext);
  const [isLoading, setIsLoading] = useState(false);
  const hotRef = useRef(null);
  const [tableData, setTableData] = useState([]);
  const [eeiInfo, setEeiInfo] = useState({});
  const [readMeData, setReadMeData] = useState({});
  const hot = hotRef.current ? hotRef.current.hotInstance : '';
  const [eeiColumns, setEeiColumns] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [wfbHeaders, setWfbHeaders] = useState({});
  const [wfbData, setWfbData] = useState({});
  const [wfbInfo, setWfbInfo] = useState({});
  const [wfbSheets, setWfbSheets] = useState({});
  const [eeiData, setEeiData] = useState([]);
  const [tableDataCopy, setTableDataCopy] = useState([]);

  const filteredId = [];

  // TODO: Move into a general hook
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);

  const ccFormContextMenu = ['copy', '---------', 'commentsAddEdit', 'commentsRemove'];

  const isEEI = selectedKPI === 'EEI';
  const isWFB = selectedKPI === 'WFB';

  //Temporary headers

  useEffect(() => {
    if (isEEI) {
      if (hzaContext.requestStatus.name === 'getEeiDataForOrganization') {
        const response = hzaContext.requestStatus;
        if (response.data) {
          const eeiResponse = response.data.eeiSheet && response.data.eeiSheet.solutionsForExcel;
          // Remove elements that have either Bl or CC unknown
          const eeiDataFiltered = cloneDeep(eeiResponse).filter(
            item => !item.cc.endsWith('Unknown') && !item.bl.endsWith('Unknown')
          );
          setEeiData(eeiDataFiltered);
          setEeiColumns(formatColumns(response.data.params));
          // TO-DO: use this to check for eei value
          setTableData(eeiDataFiltered);
          setEeiInfo({
            lastActualsQuarter: response.data.eeiSheet.lastActualsQuarter,
            updateDate: response.data.eeiSheet.updateDate,
            year: response.data.eeiSheet.year,
          });
          setReadMeData(response.data.readMeSheet);
        }
      }

      if (hzaContext.requestStatus.name === 'getEEILogsWebForm') {
        try {
          hzaContext.requestStatus.data.forEach(error => {
            const rowIndex = getEEIRowIndex(error.solutionEeiDto);
            for (const value in error.faultyValues) {
              const colIndex = getEEIColIndex(value);
              hot.getCellMeta(rowIndex, colIndex).comment = { value: error.faultyValues[value] };
              hot.setCellMeta(rowIndex, colIndex, 'className', 'red-border');
            }
          });
        } catch (err) {
          //silently catching error because rowIndex sometimes is not found
          console.error(err);
        }
      }
    }
    if (isWFB) {
      if (hzaContext.requestStatus.name === 'getWfbDataForOrganization') {
        const response = hzaContext.requestStatus;
        if (response.data) {
          // const data = testApiWfbRes.wfbSheetsDto.sheets;
          const data = response.data.wfbSheetsDto.sheets;
          const updateDate = response.data.wfbSheetsDto.updateDate;
          const readMeData = response.data.readMeSheetDto;
          const sheetItems = [];
          data.forEach(sheet => {
            sheetItems.push(sheet.name);
          });
          setWfbSheets(sheetItems);
          setWfbData(data);
          setTableData(getWFBData(data, activeTab));
          setTableDataCopy(getWFBData(data, activeTab));
          setWfbInfo({
            updateDate: updateDate,
          });
          setReadMeData(readMeData);
        }
      }
      if (hzaContext.requestStatus.name === 'downloadWfbWithErrorsForm') {
        try {
          const response = hzaContext.requestStatus;
          if (response.data) {
            const rowsWithErrors = getRowsWithErrors(response.data);
            rowsWithErrors.forEach(rowWithError => {
              const rowIndex = getRowWithErrorIndex(tableData, rowWithError);
              const colsWithError = getColsWithError(rowWithError);
              if (colsWithError && colsWithError.length > 0 && hot) {
                colsWithError.forEach(colWithError => {
                  const colIndex = hot.propToCol(colWithError);
                  const errorValue = getErrorCommentValue(rowWithError);
                  hot.getCellMeta(rowIndex, colIndex).comment = { value: errorValue };
                  hot.setCellMeta(rowIndex, colIndex, 'className', 'red-border');
                });
              }
            });
          }
          hot.render();
        } catch (err) {
          //silently catching error
          console.error(err);
        }
      }
    }
  }, [hzaContext.requestStatus]);

  useEffect(() => {
    // force render because style disappears in EEI forms
    if (isEEI && tableData && hot) {
      tableData.forEach((item, index) => {
        styleEEIData(item);
        item.eacAdj = getEacadjFormula(item);
        item.eei = getEeiFormula(item, index);
        item.eacAdjEac = getEacadjEacFormula(item);
      });

      hot.render();
    }
  });

  useEffect(() => {
    // TOGO: make sure the request is made only 1 time
    isEEI ? loadEEIData() : loadData();
  }, []);

  useEffect(() => {
    if (tableData && hot) {
      getTableComments(tableData);
      tableData.forEach(data => {
        styleTable(data);
      });
      hot.render();
    }
  }, [tableData]);

  useEffect(() => {
    if (errorComments && tableData && hot) {
      getKPIErrors();
    }
  }, [errorComments]);

  useEffect(() => {
    if (isWFB && wfbData.length) {
      loadData();
      setTableData(getWFBData(wfbData, activeTab));
    }
  }, [activeTab]);

  //TODO: will be removed when color picker will be removed
  //for now please leave this here until mustafa approves
  // const randomUniqueId = length => {
  //   let result = '';
  //   const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  //   const charactersLength = characters.length;
  //   let counter = 0;
  //   while (counter < length) {
  //     result += characters.charAt(Math.floor(generateRandom() * charactersLength));
  //     counter += 1;
  //   }
  //   return result;
  // };

  const parseColor = color => {
    const arr = [];
    color.replace(/[\d+\.]+/g, function (v) {
      arr.push(parseFloat(v));
    });
    return '#' + arr.slice(0, 3).map(toHex).join('');
  };
  const toHex = int => {
    const hex = int.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };

  const loadData = async () => {
    if (isCcForm) {
      const response = await getApi();
      const hot = hotRef.current ? hotRef.current.hotInstance : '';
      if (hot) {
        if (response && response.data) {
          setTableData(response.data);
        }
      }
    }
    if (isWFB) {
      setTableData([]);
      loadWFBData();
    }
  };

  const getWFBData = (wfbData, activeTab) => {
    let tableRows = [];
    let data = {};
    if (wfbData) {
      data = wfbData[activeTab].rows;
    }
    if (data && data.length > 0) {
      tableRows = populateTable(data);
      getWFBHeaders(activeTab, tableRows);
    } else {
      // display empty row if no data available to force the headers to appear
      tableRows = [[null, null, null, null]];
    }
    return tableRows;
  };

  const getWFBHeaders = (activeTab, tableRows) => {
    let headerData = {};
    if (tableRows) {
      tableRows.forEach(row => {
        const { gbu, bl, countries, cc, ...rowWithoutCols } = row;
        headerData = {
          activeTab: activeTab,
          year: selectedYear,
          labels: Object.keys(rowWithoutCols),
        };
      });
    }
    setWfbHeaders(headerData);
    return headerData;
  };

  //TO-DO: move to EEIForm.js
  const loadEEIData = async () => {
    const payload = {
      orgId: regionName ? '' : orgId,
      type: type,
      selectedYear: selectedYear,
      selectedKPI: selectedKPI,
      regionName: regionName,
      country: '',
    };
    const api = computeURL('eei', 'getEeiDataForOrganization');
    const params = fetchOptions({ method: 'PUT' });
    params.body = JSON.stringify(payload);

    await hzaContext.handleRequest(api, params, 'getEeiDataForOrganization');
  };

  //TO-DO: move to EEIForm.js
  const getEacadjFormula = item => {
    if (item.cbbEoy && item.eacEoy && item.cbb) {
      const sum = parseFloat(item.eacEoy) + parseFloat(item.cbb) - parseFloat(item.cbbEoy);
      return +sum.toFixed(3);
    } else {
      return '#VALUE!';
    }
  };
  //TO-DO: move to EEIForm.js
  const getEeiFormula = (item, index) => {
    if (item.isEeiManual) {
      return item.isEeiManual;
    } else {
      if (item.eac && item.eacAdj) {
        const sum = (1 - parseFloat(item.eac) / parseFloat(item.eacAdj)) * 100;
        return +sum.toFixed(1);
      } else if (eeiData[index] && eeiData[index].eei !== null) {
        return eeiData[index].eei;
      } else {
        return '#VALUE!';
      }
    }
  };
  //TO-DO: move to EEIForm.js
  const getEacadjEacFormula = item => {
    if (item.eac && item.eacAdj) {
      const sum = parseFloat(item.eacAdj) - parseFloat(item.eac);
      return +sum.toFixed(3);
    } else {
      return '#VALUE!';
    }
  };

  const styleTable = data => {
    if (isCcForm) {
      styleCCData(data);
    } else if (isEEI) {
      styleEEIData(data);
    }
  };

  const getRowStyle = (row, hot) => {
    const cells = hot.getDataAtRow(row);
    return {
      cellStyles: cells.map((cell, col) => {
        const cellElement = hot.getCell(row, col);
        if (cellElement) {
          const cellComputedStyle = window.getComputedStyle(cellElement);
          return {
            row: row,
            column: col,
            fontWeight: cellComputedStyle.getPropertyValue('font-weight'),
            backgroundColor: cellComputedStyle.getPropertyValue('background-color'),
            fontColor: cellComputedStyle.getPropertyValue('color'),
            fontStyle: cellComputedStyle.getPropertyValue('font-style'),
            textDecoration: cellComputedStyle.getPropertyValue('text-decoration-line'),
            // more tba
          };
        }
        return null;
      }),
    };
  };

  const styleCCData = row => {
    if (row.style) {
      //get physical row by using the index of our object inside the tableData array
      const rowIndex = tableData.findIndex(item => item.style === row.style);
      const cellStyles = JSON.parse(row.style).cellStyles;
      cellStyles.forEach(item => {
        if (item) {
          // const cssClass = randomUniqueId(5);
          const cellMeta = hot.getCellMeta(rowIndex, item.column);
          if (item.fontWeight && item.fontWeight !== '400') {
            hot.setCellMeta(rowIndex, item.column, 'className', cellMeta.className + ' font-bold');
          }
          if (item.backgroundColor) {
            const rgbToHex = parseColor(item.backgroundColor).substring(1);
            const backgroundColorClass = `background-${rgbToHex}`;
            hot.setCellMeta(
              rowIndex,
              item.column,
              'className',
              cellMeta.className + ` ${backgroundColorClass}`
            );
            // const styleEl = document.createElement("style");
            // document.head.appendChild(styleEl);
            // const styleSheet = styleEl.sheet;
            // const backgroundColorClass = `bgClass${rowIndex}${item.column}${cssClass}`;
            // styleSheet.insertRule(
            //   `.${backgroundColorClass}{background:${item.backgroundColor} !important;}`,
            //   0
            // );
          }
          if (item.fontColor) {
            const rgbToHex = parseColor(item.fontColor).substring(1);
            const fontColorClass = `fontColor-${rgbToHex}`;
            hot.setCellMeta(
              rowIndex,
              item.column,
              'className',
              cellMeta.className + ` ${fontColorClass}`
            );
            // const styleEl = document.createElement("style");
            // document.head.appendChild(styleEl);
            // const styleSheet = styleEl.sheet;
            // const fontColorClass = `fontClass${rowIndex}${item.column}${cssClass}`;
            // styleSheet.insertRule(`.${fontColorClass}{color:${item.fontColor} !important;}`, 0);
            // hot.setCellMeta(
            //   rowIndex,
            //   item.column,
            //   "className",
            //   cellMeta.className + ` ${fontColorClass}`
            // );
          }
          if (item.fontStyle && item.fontStyle !== 'normal') {
            hot.setCellMeta(
              rowIndex,
              item.column,
              'className',
              cellMeta.className + ' font-italic'
            );
          }
          if (item.textDecoration && item.textDecoration !== 'none') {
            hot.setCellMeta(
              rowIndex,
              item.column,
              'className',
              cellMeta.className + ' font-underline'
            );
          }
        }
      });
    }
  };

  const styleEEIData = row => {
    const whiteColumns = [5, 9, 10, 11, 12, 13, 14, 15, 19];
    const rowIndex = tableData.findIndex(item => item === row);

    if (row.nonEditable) {
      for (let i = 0; i <= 19; i++) {
        updateCell(hot, rowIndex, i);
      }
    } else if (!row.nonEditable && row.summaryRecord) {
      for (let i = 0; i <= 19; i++) {
        if (!whiteColumns.includes(i)) {
          updateCell(hot, rowIndex, i);
        }
      }
    }
  };

  const getEntryTypesFromDatalake = () => {
    const dataFromDataLake = [];
    wfbData.forEach(sheet => {
      sheet.rows &&
        sheet.rows.forEach(row => {
          row.entryTypes.forEach(entryType => {
            if (entryType.fromDataLake && !dataFromDataLake.includes(entryType.name)) {
              dataFromDataLake.push(entryType.name);
            }
          });
        });
    });
    return dataFromDataLake;
  };

  const getColsFromDatalake = () => {
    const cols = [];
    const dataFromDataLake = getEntryTypesFromDatalake();
    tableData.forEach(row => {
      const entryTypes = Object.keys(row);
      entryTypes.forEach(entryType => {
        if (dataFromDataLake.includes(entryType)) {
          const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];
          const targets = ['tar', 'act'];

          quarters.forEach(quarter => {
            targets.forEach(target => {
              const col = hot.propToCol(`${entryType}.${quarter}${target}`);
              if (!cols.includes(col)) {
                cols.push(col);
              }
            });
          });
        }
      });
    });
    return cols;
  };

  const getActualsColsFromMakeInternal = () => {
    const cols = [];

    tableData.forEach(row => {
      const makeInternalData = row['Make Internal'];
      if (makeInternalData) {
        const makeInternalKeys = Object.keys(makeInternalData);
        makeInternalKeys.forEach(key => {
          if (key.endsWith('act')) {
            const col = hot.propToCol(`Make Internal.${key}`);
            if (!cols.includes(col)) {
              cols.push(col);
            }
          }
        });
      }
    });

    return cols;
  };

  //TODO: refactor this function
  function readOnlyRenderer(instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments);
    td.style.background = '#cccccc';
  }

  //TODO: refactor this function
  function blueCellsRenderer(instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments);
    cellProperties.readOnly = true;
    td.style.background = '#aac0de';
    td.style.color = '#000000';
  }

  const getRowsWithFormulas = () => {
    const rowsWithFormulas = [];
    tableData.forEach((row, rowIndex) => {
      if (
        (row.bl === 'All' || row.countries === 'All' || row.cc === 'All') &&
        !rowsWithFormulas.includes(rowIndex)
      ) {
        rowsWithFormulas.push(rowIndex);
      }
    });
    return rowsWithFormulas;
  };

  const getBlockedQuarterCells = () => {
    const blockedCellsByRow = {};
    wfbData.forEach(sheet => {
      sheet.rows.forEach((row, rowIndex) => {
        row.entryTypes.forEach(entryType => {
          entryType.quarters.forEach(quarter => {
            if (quarter.actual.blocked) {
              const col = hot.propToCol(`${entryType.name}.${quarter.name}act`);
              if (!blockedCellsByRow[rowIndex]) {
                blockedCellsByRow[rowIndex] = { rowIndex: rowIndex, cols: [] };
              }
              if (!blockedCellsByRow[rowIndex].cols.includes(col) && typeof col === 'number') {
                blockedCellsByRow[rowIndex].cols.push(col);
              }
            }
            if (quarter.forecast.blocked) {
              const col = hot.propToCol(`${entryType.name}.${quarter.name}for`);
              if (!blockedCellsByRow[rowIndex]) {
                blockedCellsByRow[rowIndex] = { rowIndex: rowIndex, cols: [] };
              }
              if (!blockedCellsByRow[rowIndex].cols.includes(col) && typeof col === 'number') {
                blockedCellsByRow[rowIndex].cols.push(col);
              }
            }
            if (entryType.hasTarget && quarter.target.blocked) {
              const col = hot.propToCol(`${entryType.name}.${quarter.name}tar`);
              if (!blockedCellsByRow[rowIndex]) {
                blockedCellsByRow[rowIndex] = { rowIndex: rowIndex, cols: [] };
              }
              if (!blockedCellsByRow[rowIndex].cols.includes(col) && typeof col === 'number') {
                blockedCellsByRow[rowIndex].cols.push(col);
              }
            }
          });
        });
      });
    });

    return Object.values(blockedCellsByRow);
  };

  const updateCell = (hot, rowIndex, i) => {
    hot.setCellMeta(rowIndex, i, 'className', 'read-only-data');
    hot.setCellMeta(rowIndex, i, 'readOnly', true);
  };

  const getTableComments = tableData => {
    tableData.forEach(row => {
      if (row.comment) {
        const rowIndex = tableData.findIndex(item => item.comment === row.comment);
        const rowComments = JSON.parse(row.comment);
        rowComments.forEach(comment => {
          if (comment) {
            const columnIndex = rowComments.indexOf(comment);
            hot.getCellMeta(rowIndex, columnIndex).comment = { value: comment };
          }
        });
      }
    });
  };

  const getEEIRowIndex = data => {
    const periodResponse = data.period === '' ? null : data.period;
    const index = tableData.findIndex(
      item =>
        item.gbu === data.gbu &&
        item.bl === data.bl &&
        item.country === data.country &&
        item.cc === data.cc &&
        item.period === periodResponse &&
        item.projectExternalId === data.projectExternalId
    );
    return index;
  };

  const getEEIColIndex = data => {
    const index =
      eeiColumns &&
      eeiColumns.length > 0 &&
      eeiColumns.findIndex(item => item.excelHeader === data);
    return index;
  };

  const getCommentsAtRow = row => {
    const commentsPlugin = hot.getPlugin('comments');
    const colLength = hot.countCols();
    const tableComments = [];
    for (let i = 0; i <= colLength; i++) {
      tableComments.push(commentsPlugin.getCommentAtCell(row, i));
    }
    return tableComments;
  };

  const handleAfterChange = changes => {
    if (changes) {
      isCcForm ? handleSave(changes) : handleEEISave();
    }
  };

  const handleSaveComments = (row, col, value) => {
    if (hot) {
      const tableComments = getCommentsAtRow(row);
      const maxColLength = hot.countCols();
      const newCommentToInsert = value.value;

      //add comment at specific index (col number)
      if (newCommentToInsert && col >= 0 && col < maxColLength) {
        tableComments.splice(col, 1, newCommentToInsert);
      }

      // doing this because the api expects a string containing array of strings
      const stringifyChanges = JSON.stringify(tableComments);
      const changes = [[row, 'comment', '', stringifyChanges]];
      handleAfterChange(changes);
    }
  };

  const setCellsStyle = (cssClass, backgroundColor, fontColor) => {
    if (hot) {
      const changes = [];
      const selected = hot.getSelected();
      if (selected) {
        for (let index = 0; index < selected.length; index += 1) {
          const item = selected[index];
          const minCol = item[1] === -1 ? 0 : item[1];
          const minRow = item[0] === -1 ? 0 : item[0];
          const startRow = Math.min(minRow, item[2]);
          const endRow = Math.max(item[0], item[2]);
          const startCol = Math.min(minCol, item[3]);
          const endCol = Math.max(item[1], item[3]);

          for (let rowIndex = startRow; rowIndex <= endRow; rowIndex += 1) {
            for (let columnIndex = startCol; columnIndex <= endCol; columnIndex += 1) {
              const cellMeta = hot.getCellMeta(rowIndex, columnIndex);
              if (backgroundColor) {
                const backgroundColorClass = `background-${backgroundColor.substring(1)}`;
                if (cellMeta.className.includes('background')) {
                  const classToArray = cellMeta.className.split(' ');
                  const removeClassArray = classToArray.filter(
                    item => !item.includes('background')
                  );
                  const arrayToClass = removeClassArray.join(' ');
                  hot.setCellMeta(
                    rowIndex,
                    columnIndex,
                    'className',
                    arrayToClass + ` ${backgroundColorClass}`
                  );
                } else {
                  hot.setCellMeta(
                    rowIndex,
                    columnIndex,
                    'className',
                    cellMeta.className + ` ${backgroundColorClass}`
                  );
                }
                hot.render();
                // const styleEl = document.createElement("style");
                // document.head.appendChild(styleEl);
                // const styleSheet = styleEl.sheet;
                // hot.setCellMeta(
                //   rowIndex,
                //   columnIndex,
                //   "className",
                //   cellMeta.className + ` ${backgroundColorClass}`
                // );
                // styleSheet.insertRule(
                //   `.${backgroundColorClass}{background:${backgroundColor} !important;}`,
                //   0
                // );
              }
              if (fontColor) {
                const fontColorClass = `fontColor-${fontColor.substring(1)}`;
                if (cellMeta.className.includes('fontColor')) {
                  const classToArray = cellMeta.className.split(' ');
                  const removeClassArray = classToArray.filter(item => !item.includes('fontColor'));
                  const arrayToClass = removeClassArray.join(' ');
                  hot.setCellMeta(
                    rowIndex,
                    columnIndex,
                    'className',
                    arrayToClass + ` ${fontColorClass}`
                  );
                } else {
                  hot.setCellMeta(
                    rowIndex,
                    columnIndex,
                    'className',
                    cellMeta.className + ` ${fontColorClass}`
                  );
                }
                hot.render();
                // const styleEl = document.createElement("style");
                // document.head.appendChild(styleEl);
                // const styleSheet = styleEl.sheet;
                // const fontColorClass = `fontClass${rowIndex}${columnIndex}`;
                // hot.setCellMeta(
                //   rowIndex,
                //   columnIndex,
                //   "className",
                //   cellMeta.className + ` ${fontColorClass}`
                // );
                // styleSheet.insertRule(`.${fontColorClass}{color:${fontColor} !important;}`, 0);
                // hot.render();
              }
              if (cssClass) {
                if (cellMeta.className.includes(cssClass)) {
                  const removeClass = cellMeta.className.replace(cssClass, '');
                  hot.setCellMeta(rowIndex, columnIndex, 'className', removeClass);
                } else {
                  hot.setCellMeta(
                    rowIndex,
                    columnIndex,
                    'className',
                    cellMeta.className + ` ${cssClass}`
                  );
                }
                hot.render();
              }
              changes.push([rowIndex, 'style', '', JSON.stringify(getRowStyle(rowIndex, hot))]);
            }
          }
        }
        handleAfterChange(changes);
      }
    }
  };

  const hotSettings = {
    id: id,
    columns: isCcForm ? columns : eeiColumns,
    height: '98%',
    width: 'auto',
    ref: hotRef,
    filters: true,
    filter: true,
    dropdownMenu: ['filter_by_condition', 'filter_by_value', 'filter_action_bar'],
    contextMenu: isCcForm
      ? ccFormContextMenu
      : {
          items: {
            row_above: {},
            row_below: {},
            undo: {},
            redo: {},
            remove_row: {
              disabled() {
                const disabledRows = tableData.map(row => {
                  return row.summaryRecord && !row.nonEditable;
                });
                const disabledRow = disabledRows.map(value => {
                  return value;
                });
                if (disabledRow[hot.getSelectedRangeLast().to.row]) {
                  return true;
                }
              },
            },
            clear: {
              // Own custom option
              name() {
                return 'Clear row data'; // Name can contain HTML
              },
              disabled() {
                const disabledRows = tableData.map(row => {
                  return row.summaryRecord && !row.nonEditable;
                });
                const disabledRow = disabledRows.map(value => {
                  return value;
                });
                if (!disabledRow[hot.getSelectedRangeLast().to.row]) {
                  return true;
                }
              },
              callback(key, selection, clickEvent) {
                const selectionStart = selection[0].start.row;
                const selectionEnd = selection[0].end.row;
                const physicalRows = [];
                for (let i = selectionStart; i <= selectionEnd; i++) {
                  physicalRows.push(i);
                }
                handleDeleteEEI(physicalRows).then(async () => {
                  await new Promise(resolve => {
                    setTimeout(resolve, 1000);
                  });
                  return await loadEEIData();
                });
              },
            },
          },
        },
    stretchH: 'all',
    rowHeaders: true,
    undo: true,
    allowInsertColumn: false,
    allowInsertRow: isCcForm ? false : true,
    allowRemoveColumn: false,
    allowRemoveRow: true,
    beforeRemoveRow: (index, amount, physicalRows) => {
      if (isEEI) {
        handleDeleteEEI(physicalRows).then(async () => {
          await new Promise(resolve => {
            setTimeout(resolve, 1000);
          });
          return await loadEEIData();
        });
      }
    },
    redo: true,
    columnHeaderHeight: isCcForm ? 70 : 35,
    manualColumnResize: true,
    rowHeaderWidth: 30,
    className: 'className',
    comments: true,
    fixedColumnsStart: isCcForm ? 5 : '',
    licenseKey: 'non-commercial-and-evaluation',
    selectionMode: 'multiple',
    outsideClickDeselects: false,
    beforeChange: (changes, source) => {
      if (source === 'loadData') {
        return;
      }

      if (source === 'edit' && changes[0][1] === 'eei') {
        const rowIndex = changes[0][0];
        const colIndex = hot.propToCol('eei');
        const eeiValue = changes[0][3];
        tableData[rowIndex].isEeiManual = eeiValue;
      }
    },
    afterChange: (changes, source) => {
      if (changes) {
        if (isCcForm) {
          handleSave(changes);
        } else if (isEEI) {
          handleEEISave();
        }
      }
    },
    afterSetCellMeta: (row, col, key, value) => {
      if (key === 'comment') {
        handleSaveComments(row, col, value);
      }
    },
    afterFilter: () => {
      const formInstance = hotRef.current.hotInstance;
      formInstance.rowIndexMapper.notTrimmedIndexesCache.forEach(index =>
        filteredId.push(tableData[index])
      );
    },
  };

  const formatChange = change => {
    if (!change) {
      return;
    }
    const editData = {
      id: filteredId.length > 0 ? filteredId[change[0]].id : tableData[change[0]].id,
      index: change[0],
      property: change[1],
      newValue: change[3],
    };
    return editData;
  };

  const handleSave = async changes => {
    if (changes.length) {
      const payload = changes.map(row => {
        return formatChange(row);
      });
      const response = await saveApi(payload);
    }
  };

  const handleDeleteEEI = async physicalRows => {
    const tableRowsToDelete = [];
    physicalRows.forEach(row => {
      tableRowsToDelete.push(eeiData[row]);
    });

    try {
      setIsLoading(true);
      const rawEntriesFromFile = tableRowsToDelete;
      const payload = {
        rawEntriesFromFile: rawEntriesFromFile,
        year: eeiInfo.year,
      };
      await handleDelete(payload);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const transformToPayload = physicalRows => {
    const sheet = {
      name: wfbSheets[activeTab],
      referenceYear: selectedYear,
      rows: [],
    };
    physicalRows.forEach(row => {
      sheet.rows.push(wfbData[activeTab].rows[row]);
    });
    const payload = { sheet };

    return payload;
  };

  const handleDeleteWFB = async physicalRows => {
    try {
      setIsLoading(true);
      const payload = transformToPayload(physicalRows);
      await handleDelete(payload);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEEISave = async () => {
    try {
      setIsLoading(true);
      const rawEntriesFromFile = hot.getSourceData();
      const headers = hot.getColHeader();
      rawEntriesFromFile.map(row => {
        row.isEeiManual ? (row.eei = row.isEeiManual) : (row.eei = '#VALUE!');
      });
      const payload = {
        rawEntriesFromFile: rawEntriesFromFile,
        lastActualsQuarter: eeiInfo.lastActualsQuarter,
        updateDate: moment(new Date()).format('yyyy-MM-DD'),
        year: eeiInfo.year,
        presentHeaders: headers,
      };

      //Disable cells
      hot.updateSettings({
        readOnly: true,
      });

      await handleUpload(payload);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);

      // Enable cells
      hot.updateSettings({
        readOnly: false,
      });
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleDownload = async () => {
    if (isCcForm) {
      const today = moment(new Date()).format('yyyy MM DD');
      await ccExcelFormExport(`${today} Engineering Competence Center List`, tableData);
    }
  };

  const handleUndo = () => {
    if (hot) {
      try {
        hot.undo();
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleRedo = () => {
    if (hot) {
      if (isWFB && redoStack.length > 0) {
        // Get the last change from redoStack
        const lastChange = [...redoStack][redoStack.length - 1];
        const newRedoStack = [...redoStack];

        // Remove the last change from redoStack
        newRedoStack.pop();

        // Update the redoStack state
        setRedoStack(() => {
          const uniq = [...new Set(newRedoStack)];
          return uniq;
        });

        // Push the last change to undoStack
        setUndoStack(prevUndoStack => {
          const uniq = [...new Set([...prevUndoStack, lastChange])];
          return uniq;
        });

        //Set data at cell
        hot.setDataAtCell(lastChange.row, lastChange.col, lastChange.newValue, 'custom_redo');
      } else {
        try {
          hot.redo();
        } catch (e) {
          console.error(e);
        }
      }
    }
  };

  const handleBold = () => {
    if (hot) {
      setCellsStyle('font-bold');
      hot.render();
    }
  };

  const handleItalic = () => {
    if (hot) {
      setCellsStyle('font-italic');
      hot.render();
    }
  };

  const handleUnderline = () => {
    if (hot) {
      setCellsStyle('font-underline');
      hot.render();
    }
  };

  const handleFontColor = newColor => {
    if (hot) {
      const fontColor = newColor.hex;
      setCellsStyle('', '', fontColor);
    }
  };

  const handleBackgroundColor = newColor => {
    if (hot) {
      const backgroundColor = newColor.hex;
      setCellsStyle('', backgroundColor);
      hot.render();
    }
  };

  const handleSheetClick = event => {
    setActiveTab(event.target.value);
  };

  const handleSaveWfb = async tableData => {
    const sheetNumber = activeTab;
    const sheets = [];
    wfbSheets.forEach(name => {
      sheets.push({
        name: name,
        referenceYear: selectedYear,
        rows: [],
      });
    });
    tableData.forEach((tableRow, index) => {
      const { gbu, bl, countries, cc, ...rowWithoutFirstCols } = tableRow;
      sheets[sheetNumber].rows.push({
        gbu: gbu,
        bl: bl,
        countries: countries,
        cc: cc,
        entryTypes: [],
      });
      const keys = Object.keys(rowWithoutFirstCols);
      keys.forEach((key, i) => {
        sheets[sheetNumber].rows[index].entryTypes.push({
          name: key,
          quarters: [],
        });
      });
      sheets[sheetNumber].rows[index].entryTypes.forEach(type => {
        const typeName = type.name;

        //REFACTOR THIS!!! check for value not index
        if (activeTab === 0 || activeTab === 1 || activeTab === 4) {
          type.quarters.push(
            {
              name: 'Q1',
              forecast: { value: tableRow[type.name].Q1for, blocked: false },
              actual: { value: tableRow[type.name].Q1act, blocked: false },
            },
            {
              name: 'Q2',
              forecast: { value: tableRow[type.name].Q2for, blocked: false },
              actual: { value: tableRow[type.name].Q2act, blocked: false },
            },
            {
              name: 'Q3',
              forecast: { value: tableRow[type.name].Q3for, blocked: false },
              actual: { value: tableRow[type.name].Q3act, blocked: false },
            },
            {
              name: 'Q4',
              forecast: { value: tableRow[type.name].Q4for, blocked: false },
              actual: { value: tableRow[type.name].Q4act, blocked: false },
            }
          );
        } else {
          type.quarters.push(
            {
              name: 'Q1',
              target: { value: tableRow[typeName].Q1tar, blocked: false },
              forecast: { value: tableRow[type.name].Q1for, blocked: false },
              actual: { value: tableRow[type.name].Q1act, blocked: false },
            },
            {
              name: 'Q2',
              target: { value: tableRow[typeName].Q2tar, blocked: false },
              forecast: { value: tableRow[type.name].Q2for, blocked: false },
              actual: { value: tableRow[type.name].Q2act, blocked: false },
            },
            {
              name: 'Q3',
              target: { value: tableRow[typeName].Q3tar, blocked: false },
              forecast: { value: tableRow[type.name].Q3for, blocked: false },
              actual: { value: tableRow[type.name].Q3act, blocked: false },
            },
            {
              name: 'Q4',
              target: { value: tableRow[typeName].Q4tar, blocked: false },
              forecast: { value: tableRow[type.name].Q4for, blocked: false },
              actual: { value: tableRow[type.name].Q4act, blocked: false },
            }
          );
        }
      });
    });
    const payload = { sheets };
    await handleUpload(payload);
  };

  const customUndoRedo = (changes, source) => {
    if (source === 'edit') {
      const row = changes[0][0];
      const col = hot.propToCol(changes[0][1]);
      const oldValue = changes[0][2];
      const newValue = changes[0][3];
      const changesToPush = {
        row: row,
        col: col,
        oldValue: oldValue,
        newValue: newValue,
      };
      setUndoStack(prevState => {
        const uniq = [...new Set([...prevState, changesToPush])];
        return uniq;
      });
    }
    if (source.includes('UndoRedo.undo')) {
      if (undoStack.length > 0) {
        const lastChange = undoStack[undoStack.length - 1];
        const newUndoStack = [...undoStack];
        // Remove the last change from undoStack
        newUndoStack.pop();

        setUndoStack(() => {
          const uniq = [...new Set([...newUndoStack])];
          return uniq;
        });

        // Push the last change to redoStack
        setRedoStack(prevRedoStack => {
          return [...prevRedoStack, lastChange];
        });
      }
    }
  };

  return (
    <StyledEmwHotTable
      open={isOpen}
      fullScreen
      id="hot-dialog-window"
      disableAutoFocus={true}
      disableEnforceFocus={true}
    >
      <DialogTitle className="flex">
        <Grid container id="hot-dialog-header" className="flex items-center justify-between">
          <Grid item className="flex items-center" id="hot-dialog-title">
            <EmwTypography fontSize={6} classes="text-primary-500 font-bold">
              Edit file
            </EmwTypography>
            {!isCcForm && <KPIReadMeTooltip readMeData={readMeData} isWFB={isWFB} />}
          </Grid>
          <Grid item className="flex" id="hot-dialog-header-actions">
            <Grid item className="flex" id="hot-dialog-text-formatting">
              <EmwRichTextFormatting
                isCcForm={isCcForm ? true : false}
                handleUndo={handleUndo}
                handleRedo={handleRedo}
                handleBold={handleBold}
                handleItalic={handleItalic}
                handleUnderline={handleUnderline}
                handleFontColor={handleFontColor}
                handleBackgroundColor={handleBackgroundColor}
              />
            </Grid>
            <Grid item className="flex" id="hot-dialog-action-buttons">
              <EmwButton
                color="primary"
                variant="outline"
                classes="mr-s"
                size="small"
                onClick={handleCancel}
                id="btnClose"
              >
                Close
              </EmwButton>
              {isCcForm && (
                //TODO: refactor so we use same button for both cc and eei
                <EmwButton
                  variant="filled"
                  color="primary"
                  size="small"
                  onClick={handleDownload}
                  disabled={isLoading}
                  id="btnClose-modal"
                >
                  <span className="flex">
                    {isLoading ? (
                      <TdsProgressCircle />
                    ) : (
                      <TdsIcon icon="download" size="small" variant="outlined"></TdsIcon>
                    )}
                    <EmwTypography classes="font-bold">Download</EmwTypography>
                  </span>
                </EmwButton>
              )}
              {!isCcForm && (
                <KPIDownloadButton
                  selectedYear={selectedYear}
                  selectedKPI={selectedKPI}
                  regionName={regionName}
                  selectedOption={selectedOption}
                  payloadArgs={payloadArgs}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent id="hot-dialog-content">
        <div className="loading-wrapper">
          {tableData.length === 0 && <EmwProgressCircle size="small" />}
        </div>
        {!isCcForm && <EEIFormInfo eeiInfo={isEEI ? eeiInfo : wfbInfo} />}
        {!isWFB && (
          <HotTable
            root="hot"
            settings={hotSettings}
            className="handsontable"
            id={id}
            data={tableData}
            ref={hotRef}
            comments={true}
          />
        )}
        {isWFB && (
          <HotTable
            root="hot"
            id={id}
            cells={(row, col) => {
              const cellProperties = {};
              const cols = getColsFromDatalake();
              const actualsCols = getActualsColsFromMakeInternal();
              const blockedQuarterCells = getBlockedQuarterCells();
              const rowsWithFormulas = getRowsWithFormulas();
              if (col <= 3) {
                cellProperties.renderer = blueCellsRenderer;
              }

              if (actualsCols && actualsCols.includes(col)) {
                cellProperties.readOnly = true;
                cellProperties.renderer = readOnlyRenderer;
              }

              blockedQuarterCells.forEach(item => {
                if (row === item.rowIndex) {
                  if (item.cols.includes(col)) {
                    cellProperties.renderer = blueCellsRenderer;
                  }
                }
              });

              if (cols && cols.includes(col) && !rowsWithFormulas.includes(row)) {
                cellProperties.readOnly = true;
                cellProperties.renderer = readOnlyRenderer;
              }

              return cellProperties;
            }}
            filters={true}
            contextMenu={{
              items: {
                undo: {},
                redo: {},
                clear: {
                  name() {
                    return 'Clear row data';
                  },
                  disabled() {
                    const rowsWithFormulas = getRowsWithFormulas();
                    if (rowsWithFormulas.includes(hot.getSelectedRangeLast().to.row)) {
                      return true;
                    } else {
                      const cellStyles = getRowStyle(hot.getSelectedRangeLast().to.row, hot);
                      const cellColors = cellStyles.cellStyles.map(cell => {
                        return cell && cell.backgroundColor;
                      });
                      if (cellColors.includes('rgb(204, 204, 204)')) {
                        return false;
                      }
                    }
                  },
                  callback(key, selection, clickEvent) {
                    const selectionStart = selection[0].start.row;
                    const selectionEnd = selection[0].end.row;
                    const physicalRows = [];
                    for (let i = selectionStart; i <= selectionEnd; i++) {
                      physicalRows.push(i);
                    }
                    handleDeleteWFB(physicalRows).then(() => loadWFBData());
                  },
                },
              },
            }}
            dropdownMenu={true}
            settings={wfbHotSettings}
            className="handsontable"
            data={tableData}
            ref={hotRef}
            nestedHeaders={getNestedHeaders(wfbHeaders, wfbData)}
            afterChange={(changes, source) => {
              if (hot && changes) {
                customUndoRedo(changes, source);
                const data = hot.getSourceData();
                handleSaveWfb(data);
              }
            }}
            afterGetColHeader={(col, TH) => {
              const TR = TH.parentNode;
              const THEAD = TR.parentNode;
              const headerLevel =
                -1 * THEAD.childNodes.length + Array.prototype.indexOf.call(THEAD.childNodes, TR);

              const applyClass = (elem, className) => {
                if (!Handsontable.dom.hasClass(elem, className)) {
                  Handsontable.dom.addClass(elem, className);
                }
              };
              if (headerLevel >= -4 && headerLevel <= -1) {
                if (col !== -1 && col < 4) {
                  applyClass(TH, 'blue-header');
                } else if (activeTab === 4 && col > 35) {
                  applyClass(TH, 'gold-header');
                } else if (col > -1) {
                  applyClass(TH, 'pink-header');
                }
              }
            }}
          />
        )}
      </DialogContent>
      <Divider flexItem />
      {isWFB && (
        <StyledEmwHotSheetsWrapper>
          <WFBFormSheets
            wfbSheets={wfbSheets}
            activeTab={activeTab}
            handleSheetClick={handleSheetClick}
          />
        </StyledEmwHotSheetsWrapper>
      )}
      <DialogActions className="justify-between h-m" id="hot-dialog-actions">
        <EmwFooterEnvInfo isEEI={isEEI} />
      </DialogActions>
    </StyledEmwHotTable>
  );
}
