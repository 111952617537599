import React from 'react';
import BarChart from '../../../../../components/EmwCharts/components/BarChart/BarChart';

export default function UsageMonitoringGraph({
  handleClick,
  activeUsersData,
  handleClickDrillThrough,
  handleClickDrillToDetails,
  isDrillToDetailsDisabled,
}) {
  return (
    <div className="flex-1 overflow-auto shadow-s-light" id="usage-monitoring">
      <div className="chart-item h-full overflow-hidden ">
        <BarChart
          isDrillToDetailsDisabled={isDrillToDetailsDisabled}
          unit=""
          data={activeUsersData}
          name="Active Users/Month"
          tooltipInfo={
            <p className="mb-xxs">
              &#8226; The Active Users/Month chart provides a representation of user activity trends
              over time. It illustrates the number of active users each month, helping us monitor
              engagement levels, identify periods of increased or decreased activity, and set
              targets based on this data to continuously improve user adoption and application
              usage.
            </p>
          }
          info="Active Users"
          hasDrillToDetailButton={true}
          hasDrillThroughButton={true}
          handleClick={handleClick}
          handleClickDrillThrough={handleClickDrillThrough}
          handleClickDrillToDetails={handleClickDrillToDetails}
        />
      </div>
    </div>
  );
}
