import { createSelector } from '@reduxjs/toolkit';
import * as actions from './actions';
import { MODULE_NAME } from '../constants';

export const feedback = state => state[MODULE_NAME];
export const feedbackStatusSelector = createSelector(
  feedback,
  state => state[actions.FEEDBACK_STATUS]
);
export const feedbackInsideModalSelector = createSelector(
  feedback,
  state => state[actions.FEEDBACK_INSIDE_MODAL]
);
export const feedbackOutsideModalSelector = createSelector(
  feedback,
  state => state[actions.FEEDBACK_OUTSIDE_MODAL]
);

export const searchActiveSelector = createSelector(feedback, state => state[actions.SEARCH_ACTIVE]);
