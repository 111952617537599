import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { perspectiveSelector, schemaSelector } from '../../../../../../redux/selectors';
import { OrganizationCardContext } from '../../../../context/OrganizationCardContextProvider';
import { EmwButton } from '../../../../../../../../../lib/common';
import EmwTextField from '../../../../../../../../../lib/commonv2/EmwTextField';
import CheckedIcon from '../../../../../../../../../lib/icons/CheckedIcon';
import EmwTypography from '../../../../../../../../components/EmwTypography/EmwTypography';
import EmwIcon from '../../../../../../../../components/EmwIcon/EmwIcon';
import { removeCountryCode } from '../../../../../../../../utils/countryUtils';
import { ORGANIZATION_PERSPECTIVE } from '../../../../../../constants';

const NAME_FORMAT_TYPE = {
  OFF_GBU: 'Off GBU',
  OFF_BL: 'Off BL',
  OFF_GBU_OFF_BL: 'Off GBU - Off BL',
};

export default function CardName({
  countryName,
  isEdit,
  setIsEdit,
  descriptionCard,
  abbreviation,
  setAbbreviation,
}) {
  const context = useContext(OrganizationCardContext);
  const schema = useSelector(schemaSelector);
  const [baseName, setBaseName] = useState('');
  const [prefixName, setPrefixName] = useState('');

  const [countryCode, setCountryCode] = useState({ name: '', code: null });
  const [cardName, setCardName] = useState('');
  const { selectedBL, selectedCountry, selectedGBU, selectedCBU } = schema.path;
  const perspective = useSelector(perspectiveSelector);

  const formatOptions = () => {
    const options = {
      separator: '-',
      regExp: /(-[a-zA-Z]+)/,
    };

    if (context.data.cardDetails.blCode === NAME_FORMAT_TYPE.OFF_BL) {
      options.separator = '/';
      options.regExp = /(\/[a-zA-Z]+)/;
    }

    if (context.data.cardDetails.blCode === NAME_FORMAT_TYPE.OFF_GBU_OFF_BL) {
      options.separator = '/';
      options.regExp = /(\/[a-zA-Z]+)/;
    }

    return options;
  };

  let newCardName;

  useEffect(() => {
    setBaseName('');
    if (context.data) {
      if (context.data.section1.abbreviation.value) {
        setAbbreviation(context.data.section1.abbreviation.value);
      }
      if (context.data.section1.baseName) {
        setCardName(context.data.section1.prefixName);
        setBaseName(context.data.section1.baseName);
      } else {
        setCardName(context.data.section1.prefixName);
      }
      if (context.data.cardDetails.type !== 'CC') {
        setCardName(context.data.section1.name.value);
        return setPrefixName(context.data.section1.name.value);
      }

      if (!context.data.section1.prefixName) {
        const rawPrefixName = `${selectedGBU.code ? selectedGBU.code : selectedCBU.code}`;
        const regExp = /^(.*)-([a-zA-Z]{2})$/;
        let cleanPrefixName = rawPrefixName;
        if (regExp.test(selectedCBU.code)) {
          cleanPrefixName = regExp.exec(selectedCBU.code)[1];
        }

        //CREATE MODE
        let cardName = `${cleanPrefixName}/${selectedBL.code}`;
        let prefixName = `${selectedGBU.code}/${selectedBL.code}`;

        if (new RegExp(NAME_FORMAT_TYPE.OFF_BL).test(selectedBL.code)) {
          cardName = `${selectedBL.code}`.replace(` - ${NAME_FORMAT_TYPE.OFF_BL}`, '');
          prefixName = cardName;
        }

        if (new RegExp(NAME_FORMAT_TYPE.OFF_GBU).test(selectedGBU.code)) {
          cardName = `${selectedGBU.code ? selectedGBU.code : selectedCBU.code}`;
          prefixName = cardName;
        }

        setCardName(cardName);
        setPrefixName(prefixName);

        return;
      }

      if (
        context.data.section1.countries.value &&
        (context.data.section1.countries.value.length === 0 ||
          context.data.section1.countries.value.length > 1)
      ) {
        newCardName = cardName.replace(formatOptions().regExp, '');
        setPrefixName(newCardName);
      } else {
        setPrefixName(context.data.section1.prefixName);
      }
    }
  }, [context.data]);

  useEffect(() => {
    const countryCodes = countryName
      .filter(name => name)
      .map(name => {
        return name.code;
      });

    if (
      countryName.length === 0 &&
      context.type === 'CREATE' &&
      perspective === ORGANIZATION_PERSPECTIVE.BY_GBU &&
      removeCountryCode(context.data.cardDetails.blCode) === NAME_FORMAT_TYPE.OFF_GBU_OFF_BL
    ) {
      const spitName = selectedGBU.code.split(' ', 2);
      newCardName = `${spitName[0]}-${spitName[1]}`;
      context.updatePrefix(newCardName);
      return;
    }

    if (Array.isArray(countryName) && typeof countryName[0] === 'undefined') {
      return;
    }

    if (countryName.length === 0) {
      newCardName = cardName.replace(formatOptions().regExp, '');
    }

    if (countryName.length === 1) {
      const found = cardName.match(formatOptions().regExp);
      if (found) {
        newCardName = cardName.replace(
          formatOptions().regExp,
          `${formatOptions().separator}${countryCodes[0]}`
        );
      } else {
        newCardName = cardName + `${formatOptions().separator}${countryCodes[0]}`;
      }
    }

    if (countryName.length > 1) {
      newCardName = cardName.replace(formatOptions().regExp, '');
    }

    if (countryCodes[0] === 'WW') {
      newCardName = cardName.replace(formatOptions().regExp, '');
    }

    if (
      countryName.length === 1 &&
      perspective === ORGANIZATION_PERSPECTIVE.BY_COUNTRY &&
      context.type === 'CREATE' &&
      removeCountryCode(context.data.cardDetails.blCode) === NAME_FORMAT_TYPE.OFF_GBU_OFF_BL
    ) {
      const spitName = prefixName.split(' ', 2);
      newCardName = `${spitName[0]}-${spitName[1]}/${countryCodes[0]}`;
      context.updatePrefix(newCardName);
      return;
    }

    context.updatePrefix(newCardName);
  }, [countryName]);

  const onChange = event => {
    setBaseName(event.target.value);
    context.updateName(countryCode.code, event.target.value);
  };

  const onChangeCardAbbreviation = event => {
    setAbbreviation(event.target.value);
    context.updateDescription(event.target.value, descriptionCard);
  };

  const onChecked = () => {
    setIsEdit(!isEdit);

    context.updateName(countryCode.code, baseName, abbreviation, descriptionCard);
  };

  const handleEditClick = () => {
    setIsEdit(!isEdit);
  };

  const renderName = () => {
    if (!isEdit && baseName) {
      return <>({baseName})</>;
    }

    return <></>;
  };

  if (!context.data) {
    return null;
  }

  const cardHoverInfo = () => {
    if (abbreviation && !descriptionCard) {
      return abbreviation;
    }

    if (descriptionCard && !abbreviation) {
      return descriptionCard;
    }

    if (abbreviation && descriptionCard) {
      return `${abbreviation}\n${descriptionCard}`;
    }
  };

  return (
    <Grid item mr={1}>
      <Grid container alignItems="center" gap={0}>
        <Grid item mr={1} title={cardHoverInfo()}>
          <EmwTypography classes="text-primary-500" fontWeight="bold" fontSize={6}>
            {prefixName}
            {renderName()}
          </EmwTypography>
        </Grid>
        <Grid item alignItems="center" justifyContent="space-between">
          {isEdit && (
            <Grid container direction="row" alignItems={'end'}>
              <Grid item mr={1} sx={{ maxWidth: '100px' }}>
                <EmwTextField
                  id="organization-card-name"
                  name={baseName}
                  value={baseName}
                  onChange={onChange}
                  placeholder={`( CC name )`}
                />
              </Grid>
              {context.data.section1.abbreviation.visible && (
                <Grid item sx={{ maxWidth: '170px', width: '170px' }}>
                  <EmwTextField
                    id="organization-card-abbreviation"
                    name={abbreviation}
                    value={abbreviation}
                    onChange={onChangeCardAbbreviation}
                    placeholder={`( CC name abbreviation )`}
                    inputProps={{
                      maxLength: 50,
                    }}
                  />
                </Grid>
              )}
            </Grid>
          )}{' '}
        </Grid>
        <Grid item>
          {isEdit && (
            <EmwButton variant="ghost" size="small" onClick={onChecked} id="btnChecked">
              <CheckedIcon />
            </EmwButton>
          )}
          {!isEdit &&
            context.data.section1.name.visible /*&& context.data.section1.name.enabled*/ && (
              <EmwButton
                variant="filled"
                size="small"
                onClick={handleEditClick}
                disabled={!context.data.section1.name.enabled}
                title="Edit"
                classes="ml-xs cardName-editBtn"
                id={`btnEditCardName`}
              >
                <EmwIcon icon="edit" variant="outlined" size="small" />
              </EmwButton>
            )}
        </Grid>
      </Grid>
    </Grid>
  );
}
