import moment from 'moment/moment';
import { SELECT_ALL_ID } from './hooks/useGeneralFilters';

export const generateDateOptions = months => {
  const dates = [];
  for (let i = 0; i < months; i++) {
    const date = moment().subtract(i, 'months');
    dates.push(date.format('MMM YY'));
  }
  return dates;
};

export const generateDateOptionsEngineeringCompliance = months => {
  const dates = [];
  for (let i = 0; i < months; i++) {
    const date = moment().subtract(i, 'months');
    dates.push(date.format('DD/MM/YYYY'));
  }
  return dates;
};

export const getFiltersPayload = selectedFilters => {
  return {
    ccs: clearSelectAll(selectedFilters, 'cc'),
    bls: clearSelectAll(selectedFilters, 'bl'),
    gbus: clearSelectAll(selectedFilters, 'gbu'),
    countries: clearSelectAll(selectedFilters, 'country'),
  };
  // return {
  //   ccs: selectedFilters.cc.map(cc => cc.id),
  //   bls: selectedFilters.bl.map(bl => bl.id),
  //   gbus: selectedFilters.gbu.map(gbu => gbu.id),
  //   countries: selectedFilters.country.map(country => country.id),
  // };
};

const clearSelectAll = (filters, type) => {
  return filters[type].filter(filter => filter.id !== SELECT_ALL_ID).map(filter => filter.id);
};
