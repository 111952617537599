import { styled, Grid } from '@mui/material';

export const StyledManageCCHosting = styled(Grid)(({ theme, color, isCC }) => ({
  '.manage-cc-hosting-cc-name': {
    width: isCC ? '510px !important' : '470px !important',
  },
  '.manage-cc-hosting-add-section': {
    backgroundColor: '#CCF2F8',
    height: '32px',
    width: '565px',
  },
  '.manage-cc-hosting-gbu, .manage-cc-hosting-bl': {
    '.qtm-text-input > input': {
      width: '132px',
    },
    '.qtm-menu-item-list': {
      width: '132px',
      background: 'white',
      position: 'fixed',
      zIndex: 1500,
      boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 10px',
    },
  },
}));
