import React, { useEffect, useState } from 'react';
import { EmwButton } from '../../../../../../../lib/common';
import { Box, DialogContent, Divider, Grid } from '@mui/material';
import { StyledExportDialog } from './styled';
import HeaderInfo from './Header/HeaderInfo';
import HeaderCheckList from './Header/HeaderCheckList';
import ExportOrganizationSectionLeft from './SectionLeft/ExportOrganizationSectionLeft';
import ExportOrganizationSectionRight from './SectionRight/ExportOrganizationSectionRight';
import ExportOrganizationContextProvider from './context/ExportOrganizationContext';
import ExportFileIcon from '../../../../../../../lib/icons/ExportFileIcon';
import TourElement from '../../../../../../components/Tour/TourElement/TourElement';
import FeedbackButton from '../../../../../feedback/FeedbackButton';
import { useDispatch } from 'react-redux';
import { feedbackInsideModal } from '../../../../../feedback/redux/actions';
import { userActivity } from '../../../../../userActivity/redux/actions';
import { USER_ACTIVITY_SERVICES } from '../../../../../userActivity/constants';
import { v4 as uuidv4 } from 'uuid';

export default function ExportOrganization({ data }) {
  const [isOpen, setIsOpen] = useState(false);
  const title = 'Export Engineering Organization';
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      dispatch(
        userActivity({
          uniqueId: uuidv4(),
          service: USER_ACTIVITY_SERVICES.ENG_ORG_EXPORT,
          details: 'EXPORT_ORG',
          uri: '',
        })
      );
    }
  }, [isOpen]);

  const handleClick = () => {
    setIsOpen(!isOpen);
    dispatch(feedbackInsideModal(true));
  };

  return (
    <ExportOrganizationContextProvider>
      <Box
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        id="export-organization-dialog"
      >
        <Grid item flexGrow={1}>
          <Grid container>
            <EmwButton
              id="btnExportOrg"
              title={title}
              size="small"
              onClick={handleClick}
              disabled={false}
              variant="ghost"
            >
              <ExportFileIcon width={21} height={26} />
            </EmwButton>

            {isOpen && (
              <StyledExportDialog open={isOpen} fullWidth>
                <Grid className="px-l ">
                  <TourElement id="tour-header-export">
                    <HeaderInfo setIsOpen={setIsOpen} data={data} />
                    <HeaderCheckList data={data} />
                  </TourElement>
                  <Divider flexItem />
                </Grid>

                <DialogContent>
                  <Grid container id="export-dialog-content">
                    <Grid item sm={12} md={7} id="export-dialog-section-left">
                      <ExportOrganizationSectionLeft data={data} />
                    </Grid>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ marginTop: '25px', marginBottom: '25px', marginLeft: '-5px' }}
                    />
                    <Grid
                      item
                      sm={12}
                      md={4}
                      id="export-dialog-section-right"
                      sx={{ marginLeft: '25px' }}
                    >
                      <ExportOrganizationSectionRight data={data} />
                    </Grid>
                  </Grid>
                </DialogContent>
                <FeedbackButton title={USER_ACTIVITY_SERVICES.ENG_ORG_EXPORT} />
              </StyledExportDialog>
            )}
          </Grid>
        </Grid>
      </Box>
    </ExportOrganizationContextProvider>
  );
}
