import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { BarChart, GroupedChart } from '../../../../components/EmwCharts';
import GeneralFiltersContainer from '../../../../components/EmwGeneralFilters/GeneralFiltersContainer';
import EmwTypography from '../../../../components/EmwTypography/EmwTypography';
import { StyledEngineeringCompliance } from './styled';
import useEngineeringCompliance from './hooks/useEngineeringCompliance';
import TimeFrame from '../../../../components/EmwCharts/components/Filters/components/TimeFrame/TimeFrame';
import { QtmMultiselect } from '@qtm/react';
import { ORGANIZATION_CC_FILTER as options } from '../../../organization/constants';
import { filterCCTypeSelector } from '../../../organization/redux/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { changeCCType } from '../../../organization/redux/actions';
import { generateDateOptionsEngineeringCompliance } from '../../../../components/EmwGeneralFilters/utils';
import MultipleBarChart from '../../../../components/EmwCharts/components/BarChart/MultipleBarChart';
import { info, colorMapping } from './constants';
import AccessDenied from '../AccessDenied/AccessDenied';

export default function EngineeringCompliance({ user }) {
  const { chartsData, stateData, setStateData } = useEngineeringCompliance();
  const orgFilter = useSelector(filterCCTypeSelector);
  const [filterValues, setFilterValues] = useState(orgFilter);
  const dispatch = useDispatch();
  const allFilters = options.map(item => item.value);

  const defaultDate = generateDateOptionsEngineeringCompliance(21)[0];
  const defaultPeriod = '12 Months';

  const onCcTypeValueChanged = event => {
    const selectedOptions = event.detail.selectedOptions;
    let selectedOptionsToDispatch = [];
    let newFilters = [];
    if (selectedOptions.length === 0) {
      newFilters = [];
      setFilterValues([]);
    } else if (selectedOptions.includes('ALL')) {
      setFilterValues(prevValues => {
        if (prevValues && !prevValues.includes('ALL')) {
          newFilters = allFilters;
          selectedOptionsToDispatch = allFilters;
          return allFilters;
        } else {
          const optionsToDeselect = selectedOptions.filter(item => item !== 'ALL');
          newFilters = [...optionsToDeselect];
          selectedOptionsToDispatch = newFilters;
          return newFilters;
        }
      });
    } else {
      setFilterValues(prevValues => {
        if (prevValues && prevValues.includes('ALL') && !selectedOptions.includes('ALL')) {
          newFilters = [];
          selectedOptionsToDispatch = [];
          return [];
        } else {
          const optionsToDeselect = selectedOptions.filter(item => item !== 'ALL');
          newFilters = [...optionsToDeselect];
          selectedOptionsToDispatch = newFilters;
          return newFilters;
        }
      });
    }
    dispatch(changeCCType(selectedOptionsToDispatch));
  };

  useEffect(() => {
    if (!orgFilter.length) {
      setFilterValues([]);
    }
  }, [orgFilter]);

  return (
    <>
      {user.showAdminPage ? (
        <GeneralFiltersContainer>
          <StyledEngineeringCompliance className="flex flex-col flex-1 overflow-auto px-xs pb-xs">
            <>
              <div className="title flex mb-m">
                <EmwTypography classes="text-primary-500" fontSize={4} fontWeight="bold">
                  Engineering Compliance
                </EmwTypography>

                <div className="flex items-center ml-auto mr-s">
                  <EmwTypography classes="text-scale-9 mr-xxs" fontSize="8">
                    CC TYPE
                  </EmwTypography>
                  <QtmMultiselect
                    size="small"
                    placeholder="Select All"
                    options={options}
                    onValueChanged={onCcTypeValueChanged}
                    classes={'filter-cc-types-dropdown'}
                    value={filterValues}
                  ></QtmMultiselect>
                </div>
                <TimeFrame
                  filters={stateData}
                  setFilters={setStateData}
                  defaultPeriod={defaultPeriod}
                  defaultDate={defaultDate}
                />
              </div>
              <div className="flex flex-wrap flex-1 justify-between overflow-y-auto overflow-x-hidden pr-xxs">
                <div className="flex flex-col flex-1 charts-container px-xxs">
                  <div className="mb-xs">
                    <Grid container spacing={1}>
                      <Grid item sm={12} md={4}>
                        <BarChart
                          data={chartsData.emHoeos}
                          name="CCs with EM and HoEO defined"
                          tooltipInfo={
                            <>
                              <p className="mb-xxs">
                                &#8226; We count only the Competence Centers with both EM/HoEO
                                defined. The ratio is provided according with total nr of Competence
                                Centers.
                              </p>
                              <p>
                                &#8226; Quarterly – snapshot at the end of each quarter of cc’s,
                                evaluated on each day. At the end of Q, the value will be fixed. The
                                current quarter will be the last one on right end side, and will be
                                shifted left at each quarter beginning.
                              </p>
                            </>
                          }
                          info="Percentage with EM and HoEO defined"
                        />
                      </Grid>
                      <Grid item sm={12} md={4}>
                        <BarChart
                          data={chartsData.disciplines}
                          name="CCs with disciplines defined with HoDs"
                          tooltipInfo={
                            <>
                              <p>
                                Show Competence Centers with at least 1 discipline and 1 HoD defined
                              </p>
                            </>
                          }
                          info="Percentage of CCs having Disciplines defined with HoDs"
                        />
                      </Grid>
                      <Grid item sm={12} md={4}>
                        <BarChart
                          data={chartsData.allComponents}
                          name="CCs with all organisation components defined"
                          tooltipInfo={
                            <>
                              <p>
                                &#8226; Show Competence Centers that have an EM, HoEO, HoED, HoD
                                defined with disciplines names and HoD’s,
                                <p>Organization type defined (cc, dcc, etc),</p>
                                <p>
                                  Engineering Domain defined (software, hard - At least one checked)
                                  and at least one referent, country and declared headcount.
                                </p>
                              </p>
                            </>
                          }
                          info={''}
                          comingSoon={false}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div className="mb-xs">
                    <Grid container spacing={1}>
                      <Grid item sm={12} md={4}>
                        <BarChart
                          data={chartsData.referents}
                          name="CCs with 50% of referents defined"
                          tooltipInfo={
                            <>
                              <p className="mb-xxs">
                                &#8226; We count only the Competence Centers having equal or above
                                50% referents defined
                              </p>
                              <p className="mb-xxs">
                                &#8226; All referents (7) without KPI Stewart
                              </p>
                              <p>
                                &#8226; The ratio is provided according to total nr of Competence
                                Centers
                              </p>
                            </>
                          }
                          info="CC with 50% referents defined"
                        />
                      </Grid>
                      <Grid item sm={12} md={8}>
                        <GroupedChart
                          data={chartsData.allReferents}
                          name="Percentage of referents defined, per type"
                          tooltipInfo={
                            <>
                              <p className="mb-xxs">
                                &#8226; Evaluate only at the Competence Centers level the reference
                                according with governance (no BL, BU GBU)
                              </p>
                              <p className="mb-xxs">
                                &#8226; Evolution over time: show at each quarter per referant type
                                (Data referent: Q1,Q2, Q3,Q4), Ecodesign (Q1,q2,q3,q4), etc…
                              </p>
                            </>
                          }
                          info=""
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div className="pb-xs">
                    <Grid container spacing={1}>
                      <Grid item sm={12} md={4}>
                        <MultipleBarChart
                          data={chartsData.hodsNoJfOrEm}
                          keys={['hodWrongJF', 'hodWrongEM']}
                          name="HoD who do not have their EM as Manager or do not belong to JFG"
                          colorMapping={colorMapping}
                          info={info}
                          tooltipInfo={
                            <>
                              <p className="mb-xxs">
                                &#8226; We count HoDs that do not have EM as Manager and do not
                                belong to a Discipline Management Job Family.
                              </p>
                            </>
                          }
                          unit=""
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </>
          </StyledEngineeringCompliance>
        </GeneralFiltersContainer>
      ) : (
        <AccessDenied />
      )}
    </>
  );
}
