import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Divider } from '@mui/material';
import './styled';
import { EmwButton, EmwDropDownList } from '../../../../../../../lib/common';
import KPIDownloadButton from './KPIDownloadButton';
import EmwTypography from '../../../../../../components/EmwTypography/EmwTypography';
import KPIEditButton from './KPIEditButton';
import KPIUploadButton from './KPIUploadButton';
import { orgDetailsSelector, schemaSelector } from '../../../../redux/selectors';

export default function KPIActions({
  handleSelectFile,
  selectedOption,
  selectedFile,
  cardDetails,
  yearList,
  selectedKPI,
  isGroup,
  isRegion,
  isLoading,
  handleUpload,
  defaultYear,
  countryName,
  errorComments,
  errorFileName,
  getKPIErrors,
  isFromByCountry,
  handleDelete,
  regionName,
}) {
  const [selectedDownloadYear, setSelectedDownloadYear] = useState('');
  const [selectedEditYear, setSelectedEditYear] = useState('');
  const org = useSelector(orgDetailsSelector);
  const schema = useSelector(schemaSelector);
  const isCC = org.list.data.cardDetails.type === 'CC';
  const countryNode = schema.path.selectedCountry.name ? schema.path.selectedCountry.name : '';
  const regionNode = schema.path.selectedRegion.code ? schema.path.selectedRegion.code : '';
  const groupNode = schema.path.selectedGROUP.id ? schema.path.selectedGROUP.id : '';
  const gbuNode = schema.path.selectedGBU.id ? schema.path.selectedGBU.id : '';
  const blNode = schema.path.selectedBL.id ? schema.path.selectedBL.id : '';
  const ccNode = isCC ? org.list.data.cardDetails.id : '';
  const source = 'file';
  const payloadArgs = {
    countryNode: countryNode,
    regionNode: regionNode,
    groupNode: groupNode,
    gbuNode: gbuNode,
    blNode: blNode,
    ccNode: ccNode,
  };

  const inputRef = useRef(null);

  useEffect(() => {
    setSelectedDownloadYear(selectedOption ? defaultYear : '');
    setSelectedEditYear(selectedOption ? defaultYear : '');
  }, [selectedOption]);

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleChangeEditYear = e => {
    setSelectedEditYear(e.target.value);
  };

  const handleChangeDownloadYear = e => {
    setSelectedDownloadYear(e.target.value);
  };

  return (
    <>
      <Grid id="kpi-actions">
        {!isGroup && (
          <>
            <Grid container className="pb-xxs flex justify-between mb-xxs" id="upload-container">
              <div id="upload-label">
                <EmwTypography classes="text-bluegrey-900" fontSize={7}>
                  Upload Excel
                </EmwTypography>
                {selectedFile && selectedFile.name && (
                  <EmwTypography
                    component="caption-1"
                    classes="text-bluegrey-500 text-scale-8 py-xxs"
                  >
                    Selected: {selectedFile.name}
                  </EmwTypography>
                )}
                {!selectedFile && (
                  <EmwTypography
                    component="caption-1"
                    classes="text-bluegrey-400 text-scale-8 py-xxs"
                  >
                    Upload KPI file
                  </EmwTypography>
                )}
              </div>
              <Grid item className="kpi-modal-btn flex">
                <input
                  style={{ display: 'none' }}
                  ref={inputRef}
                  type="file"
                  accept=".xlsx, .xls"
                  onChange={handleSelectFile}
                />
                <EmwButton
                  variant="filled"
                  color="primary"
                  size="small"
                  onClick={handleClick}
                  classes="flex browse-button mr-xs p-0"
                  disabled={!selectedOption}
                >
                  <EmwTypography classes="font-bold">Browse</EmwTypography>
                </EmwButton>
                <KPIUploadButton
                  selectedOption={selectedOption}
                  source={source}
                  selectedFile={selectedFile}
                  handleUpload={handleUpload}
                  isLoading={isLoading}
                />
              </Grid>
            </Grid>
            <Divider />

            <Grid
              container
              className="pt-xxs pb-xs flex mb-xxs justify-between"
              id="download-container"
            >
              <div id="download-label">
                <EmwTypography classes="text-bluegrey-900" fontSize={7}>
                  Download Excel
                </EmwTypography>
                <EmwTypography
                  component="caption-1"
                  classes="text-bluegrey-400 text-scale-8 pt-xxs"
                >
                  Download KPI File
                </EmwTypography>
              </div>

              <Grid item className="flex flex-row justify-between">
                <Grid item className="flex mr-xs" id="select-year-dropdown">
                  <EmwDropDownList
                    disabled={!selectedOption}
                    options={yearList}
                    onDropDownListChanged={handleChangeDownloadYear}
                    value={selectedDownloadYear}
                    placement="bottom"
                    inputId="year"
                    placeHolder="Year"
                  />
                </Grid>
                <Grid item className="flex kpi-modal-btn">
                  <KPIDownloadButton
                    selectedYear={selectedDownloadYear}
                    selectedKPI={selectedKPI}
                    isRegion={isRegion}
                    regionName={isRegion ? 'North America' : ''}
                    cardDetails={cardDetails}
                    selectedOption={selectedOption}
                    payloadArgs={payloadArgs}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Divider />

            <Grid
              container
              className="pt-xxs pb-xs flex mb-xxs justify-between"
              id="download-container"
            >
              <div id="download-label">
                <EmwTypography classes="text-bluegrey-900" fontSize={7}>
                  Edit Excel Form
                </EmwTypography>
                <EmwTypography
                  component="caption-1"
                  classes="text-bluegrey-400 text-scale-8 pt-xxs"
                >
                  Edit Excel Form
                </EmwTypography>
              </div>

              <Grid item className="flex flex-row justify-between">
                <Grid item className="flex mr-xs" id="select-year-dropdown">
                  <EmwDropDownList
                    disabled={!selectedOption}
                    options={yearList}
                    onDropDownListChanged={handleChangeEditYear}
                    value={selectedEditYear}
                    placement="bottom"
                    inputId="year"
                    placeHolder="Year"
                  />
                </Grid>
                <Grid item className="flex kpi-modal-btn">
                  <KPIEditButton
                    selectedOption={selectedOption}
                    handleUpload={handleUpload}
                    selectedEditYear={selectedEditYear}
                    selectedKPI={selectedKPI}
                    cardDetails={cardDetails}
                    regionName={isRegion ? regionName : ''}
                    countryName={countryName}
                    errorComments={errorComments}
                    errorFileName={errorFileName}
                    getKPIErrors={getKPIErrors}
                    isFromByCountry={isFromByCountry}
                    handleDelete={handleDelete}
                    payloadArgs={payloadArgs}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        {isGroup && (
          <Grid
            container
            className="pt-xxs pb-xs flex mb-xxs justify-between"
            id="download-container"
          >
            <div id="download-label">
              <EmwTypography classes="text-black-100 font-medium text-scale-7">
                Download Excel
              </EmwTypography>
              <EmwTypography component="caption-1" classes="text-bluegrey-400 text-scale-8 pt-xxs">
                Download KPI File
              </EmwTypography>
            </div>
            <Grid item className="flex flex-row justify-between">
              <Grid item className="flex mr-xs" id="select-year-dropdown">
                <EmwDropDownList
                  disabled={!selectedOption}
                  options={yearList}
                  onDropDownListChanged={handleChangeDownloadYear}
                  value={selectedDownloadYear}
                  placement="bottom"
                  inputId="year"
                  placeHolder="Year"
                />
              </Grid>
              <Grid item className="flex kpi-modal-btn">
                <KPIDownloadButton
                  selectedYear={selectedDownloadYear}
                  selectedKPI={selectedKPI}
                  regionName={isRegion ? 'North America' : ''}
                  cardDetails={cardDetails}
                  selectedOption={selectedOption}
                  payloadArgs={payloadArgs}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Divider />
      </Grid>
    </>
  );
}
