import React, { Suspense, useEffect, useState } from 'react';
import { Resizable } from 're-resizable';
import { useDispatch } from 'react-redux';
import { FILTERS_SECTIONS, RESIZABLE, RESIZABLE_CLOSED } from './constants';
import { StyledEmwGeneralFilters } from './components/styled';
import GeneralFiltersHeader from './components/GeneralFiltersHeader/GeneralFiltersHeader';
import GeneralFiltersWrapper from './components/GeneralFiltersWrapper/GeneralFiltersWrapper';
import GeneralFiltersCard from './components/GeneralFiltersCard/GeneralFiltersCard';
import { useSelector } from 'react-redux';
import { treeDataSelector } from '../../features/header/redux/selectors';
import useGeneralFilters from './hooks/useGeneralFilters';
import {
  availableFiltersSelector,
  selectedGeneralFilters,
  availableDrillToDetailFiltersSelector,
  selectedDrillToDetailFilters,
} from './redux/selectors';
import { clearAllFilters } from './redux/actions';
import { generateRandom } from '../../../util/generateRandom';

export default function EmwGeneralFilters({ onResize }) {
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(true);
  const { buildFilters } = useGeneralFilters();
  const treeData = useSelector(treeDataSelector);
  const availableFilters = useSelector(availableFiltersSelector);
  const selectedFilters = useSelector(selectedGeneralFilters);

  const availableDrillToDetailFilters = useSelector(availableDrillToDetailFiltersSelector);

  let items = [];
  useEffect(() => {
    return () => {
      dispatch(clearAllFilters(generateRandom()));
    };
  }, []);

  useEffect(() => {
    if (
      treeData.list.hasOwnProperty('data') &&
      selectedFilters.country.length === 0 &&
      selectedFilters.bl.length === 0 &&
      selectedFilters.cc.length === 0 &&
      selectedFilters.gbu.length === 0
    ) {
      buildFilters(treeData.list.data);
    }
  }, [treeData, selectedFilters]);

  const handleToggleFilters = event => {
    setIsExpanded(!isExpanded);
  };

  const getAvailableOrganization = () => {
    if (!window.location.hash.includes('#drilltodetails')) {
      items = availableFilters;
    } else {
      items = availableDrillToDetailFilters;
    }
    return items;
  };
  return (
    <>
      {isExpanded && (
        <Resizable {...RESIZABLE} onResize={onResize}>
          <StyledEmwGeneralFilters>
            <Suspense fallback={'Loading ...'}>
              <GeneralFiltersHeader toggle={handleToggleFilters} isExpanded={isExpanded} />
              <GeneralFiltersWrapper>
                <GeneralFiltersCard
                  type={FILTERS_SECTIONS.COUNTRY}
                  items={getAvailableOrganization().country}
                />
                <GeneralFiltersCard
                  type={FILTERS_SECTIONS.GBU}
                  items={getAvailableOrganization().gbu}
                />
                <GeneralFiltersCard
                  type={FILTERS_SECTIONS.BL}
                  items={getAvailableOrganization().bl}
                />
                <GeneralFiltersCard
                  type={FILTERS_SECTIONS.CC}
                  items={getAvailableOrganization().cc}
                />
              </GeneralFiltersWrapper>
            </Suspense>
          </StyledEmwGeneralFilters>
        </Resizable>
      )}
      {!isExpanded && (
        <StyledEmwGeneralFilters {...RESIZABLE_CLOSED}>
          <GeneralFiltersHeader toggle={handleToggleFilters} isExpanded={isExpanded} />
        </StyledEmwGeneralFilters>
      )}
    </>
  );
}
