import { Grid, styled } from '@mui/material';
import { getZoom } from '../../../../utils/sizeUtils';

export const StyledDiagram = styled(Grid)(({ theme }) => {
  return {
    height: '100%',
    width: 'auto',
    '&.by-country-diagram': {
      paddingRight: '42px',
    },
    '.filter-cc-types-dropdown': {
      marginRight: '3px',
      marginTop: '-10px',

      '.qtm-text-input > input': {
        height: '30px',
        paddingRight: '0px',
      },
    },

    '.diagram-header': {
      display: 'flex',
      position: 'relative',

      '.diagram-logo-container': {
        minWidth: '155px!important',
        maxWidth: '155px!important',
      },

      '.thales-logo': {
        display: 'flex',
        justifyContent: 'center',
      },

      '.diagram-headings': {
        display: 'flex',
        height: '50px',
        position: 'relative',
        marginLeft: '70px',

        '.divider': {
          height: '2px',
          top: '22px',
          width: '100%',
          backgroundColor: theme.palette.primary.main,
        },

        '.diagram-headings-unit': {
          height: '50px',
          minWidth: '200px',
          marginRight: '26px',
          color: theme.palette.bluegrey[700],

          '.MuiDivider-root': {
            height: '2px',
            backgroundColor: theme.palette.primary.main,
          },

          '.selected-unit': {
            p: {
              maxWidth: '200px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          },
        },
      },
    },

    '#diagram-body': {
      marginTop: '22px',
      flexGrow: 1,

      '.diagram-node-svg': {
        minWidth: '26px',
        maxWidth: '6',
        position: 'relative',

        svg: {
          position: 'absolute',
          width: '26px',

          g: {
            position: 'absolute',

            path: {
              fill: 'transparent',
              pointerEvents: 'stroke',
              strokeWidth: 2,
            },
          },
        },
      },

      '.diagram-svg-column': {
        minWidth: '26px',
        maxWidth: '6',
        position: 'relative',
      },

      '.diagram-node': {
        minWidth: '200px',
        maxWidth: '200px',
        height: '52px',
        marginBottom: '13px',
        cursor: 'default',

        '.diagram-node-link': {
          display: 'flex',
          height: '24px',
          cursor: 'pointer',
          overflow: 'hidden',
          borderRadius: '4px',
          backgroundColor: theme.palette.bluegrey[200],

          '&.greyed': {
            backgroundColor: '#DEDFE6',
          },

          '&.hasAllocation': {
            backgroundColor: '#7986ff!important',
          },

          '&.hasStar': {
            backgroundColor: theme.palette.bluegrey[200],
          },

          '&.selected': {
            backgroundColor: theme.palette.primary[400] + '!important',
          },

          '&.unknown': {
            backgroundColor: theme.palette.orange[200],
          },
          '&.dcc': {
            backgroundColor: '#99DDD2',
          },
          '&.dpt': {
            backgroundColor: '#99E4F1',
          },
          '&.cc': {
            backgroundColor: '#CCEEE8',
          },
          '&.other': {
            backgroundColor: '#CDD0EA',
          },
          '&.notSet': {
            backgroundColor: '#9BA1D5',
          },

          '.node-star': {
            display: 'flex',
            alignItems: 'center',
            width: '25px',
            height: '100%',
            justifyContent: 'center',
            backgroundColor: theme.palette.primary.main,
            'tds-icon': {
              i: {
                color: 'white',
                fontSize: '18px',
              },
            },
          },

          '.node-grey-star': {
            display: 'flex',
            alignItems: 'center',
            width: '25px',
            height: '100%',
            justifyContent: 'center',
            backgroundColor: theme.palette.primary.main,
            'tds-icon': {
              i: {
                color: theme.palette.bluegrey[200],
                fontSize: '18px',
              },
            },
          },
        },

        '.diagram-node-info': {
          marginTop: '3px',
          flexWrap: 'nowrap',
          '.node-allocated': {
            'tds-icon': {
              marginLeft: '3px',
              i: {
                fontSize: '16px',
              },
            },
          },
        },

        '.diagram-node-name p': {
          maxWidth: '180px',
          marginLeft: '1px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },

        '.selected p': {
          fontWeight: 700,
          color: 'black',
        },
        'p, tds-typography': {
          ...theme.typography['caption-1'],
          color: theme.palette.bluegrey[400],
        },
      },
    },
  };
});
