import React, { useMemo } from 'react';
import EngCategory from './components/EngCategory';

export const VIEW_MODE = {
  VIEW: 'viewMode',
  EDIT: 'editMode',
};

export default function EngCategories({
  engCategories,
  viewMode = VIEW_MODE.VIEW,
  targetField,
  section,
  categories,
}) {
  return useMemo(() => {
    return engCategories
      .filter(engCategory => engCategory[viewMode].visible)
      .sort((a, b) => a.position - b.position)
      .map((engCategory, index) => {
        return (
          <EngCategory
            key={`${engCategory.type}-${index}`}
            engCategory={engCategory}
            viewMode={viewMode}
            targetField={targetField}
            section={section}
            categories={categories}
          />
        );
      });
  }, [engCategories]);
}
